import React, { Component } from "react";
import TablaEmprendedor from '../../Table/index';

class cumpleanios extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'IdCliente', label: 'ID Cliente', minWidth: 100 },
                    { id: 'Nombre', label: 'Nombre', minWidth: 120 },
                    { id: 'Telefono', label: 'Teléfono', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'FecNac', label: 'Fecha Nacimiento', minWidth: 120, format: value => value.toLocaleString(),},
                    { id: 'Descuento', label: 'Descuento', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Estatus', label: 'Estatus', minWidth: 120, format: value => value.toFixed(2), },
                  ]}
              />
          );
    }
  }

  export default cumpleanios