const tutorialStepsDesktop = [
    {
      label: 'Ingresa en la opción "Tienda".',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/1.jpg',
    },
    {
      label: 'Actualiza tus datos.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/2.jpg',
    },
    {
        label: 'Agrega el producto al carrito.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/3.jpg',
      },
      {
        label: 'El carrito se actualiza.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/4.jpg',
      },
      {
        label: 'Puedes filtrar los productos.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/5.jpg',
      },
      {
        label: 'Puedes buscar productos, por su nombre.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/6.jpg',
      },
      {
        label: 'Y también puedes buscar productos, por su código.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/7.jpg',
      },
      {
        label: 'Revisa tu pedido, ingresando un el carrito de compras.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/8.jpg',
      },
      {
        label: 'Por ultimo, confirma tu pedido.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/9.jpg',
      },
      {
        label: 'Verifica tu pedido y realiza tu pago.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/10.jpg',
      },
      {
        label: 'Envía tu comprobante de pago vía WhatsApp.',
        imgPath:
          'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Desktop/11.jpg',
      },
   
]
const tutorialStepsMovil = [
    {
      label: 'Ingresa en la opción "Tienda".',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/1.jpg',
    },
    {
      label: 'Actualiza tus datos.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/2.jpg',
    },
    {
      label: 'Agrega el producto al carrito.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/3.jpg',
    },
    {
      label: 'El carrito se actualiza.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/4.jpg',
    },
    {
      label: 'Puedes filtrar los productos.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/5.jpg',
    },
    {
      label: 'Puedes buscar productos, por su nombre.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/6.jpg',
    },
    {
      label: 'Y también puedes buscar productos, por código.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/7.jpg',
    },
    {
      label: 'Revisa tu pedido, ingresando un el carrito de compras.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/8.jpg',
    },
    {
      label: 'Por ultimo, confirma tu pedido.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/9.jpg',
    },
    {
      label: 'Verifica tu pedido y realiza tu pago.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/10.jpg',
    },
    {
      label: 'Envía tu comprobante de pago vía WhatsApp.',
      imgPath:
        'https://zermatinternal.com:3030/ImagenesPaises/img/Ayuda/Movil/11.jpg',
    },
 
]

export default {tutorialStepsDesktop,tutorialStepsMovil};