import React, {Component} from 'react';
import { withStyles} from '@material-ui/core/styles';
import useStyles  from '../../styles/ui-styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';



class Tabla extends Component {
    constructor(props){
        super(props)
        this.state = {
            rowsPerPage : 5, 
            page : 0,
          }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangeRowsPerPage2 = this.handleChangeRowsPerPage2.bind(this);
    }

    componentDidMount(){
        this.setState({columas: this.props.titulos});
    }

    handleChangePage(event, newPage) {
        const pag = this.state.page+1
        this.setState({page:pag});
        newPage = this.state.page+1;
    }
    
    handleChangeRowsPerPage(event) {
        this.setState({rowsPerPage: +event.target.value});
        this.setState({page:0});
    }

    handleChangeRowsPerPage2(event, newPage) {
      const pag = this.state.page-1
      this.setState({page:pag})
      newPage = this.state.page-1
    }

    render() {  
        const {classes} = this.props;
        const columns = this.props.columns;
        const rows = this.props.rows;
        return (
            <Paper className={classes.width100}>
              <div className={classes.tableWrapper}>
                <Table stickyHeader>
                  <TableHead>
                    {columns && <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>}
                  </TableHead>
                  <TableBody>
                    {rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {columns.map(column => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                 
                  component="div"
                  count={rows.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}

                  backIconButtonProps={{
                    'aria-label': 'previous page',
                    'onClick': this.handleChangeRowsPerPage2
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                    'onClick': this.handleChangePage
                  }}

                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              
              </div>              
            </Paper>
          )
    }

}
export default (withStyles)(useStyles)(Tabla);
