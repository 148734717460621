import axios from 'axios'
import url from './url'

const Api = {
    getAcceso: async (usuario,password,pais,ipPub,platform,os,browser) => {   
        var data = {
            'usuario': usuario,
            'password': password,
            'pais': pais,
            'ipPub': ipPub,
            'platform': platform,
            'os': os,
            'browser': browser
        }      
        return await axios.post(url+'/usuarios/acceso',
            data,
            {headers: {
                        'Content-Type': 'application/json'                       
                    }
            }
        ) 
    },
    getToken: async (usuario,pais) => {   
        var data = {
            'usuario': usuario,
            'pais': pais,
        }
        return await axios.post(url+'/usuarios/acceso/token',
            data,
            {headers: {
                        'Content-Type': 'application/json'      
                        ,'Authorization': localStorage.getItem("Authorization")                 
                    }
            }
        ) 
    },
    getValidaSesionLg: async (usuario,pais) => {   
        return await axios.get(url+`/usuarios/acceso/ValidaSesionLg/${pais}/${usuario}`,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
        ) 
    },
    CierraSesionLg: async (usuario,pais) => {   
        return await axios.get(url+`/usuarios/acceso/CierraSesionLg/${pais}/${usuario}`,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
        ) 
    },
    getValidaSesionTk: async (usuario,pais) => {   
        return await axios.get(url+`/usuarios/acceso/ValidaSesionTk/${pais}/${usuario}`,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
        ) 
    },
    CierraSesionTk: async (usuario,pais) => {   
        return await axios.get(url+`/usuarios/acceso/CierraSesionTk/${pais}/${usuario}`,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
        ) 
    },
   


}
export default Api