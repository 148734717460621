import React, {Component} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';

import useStyles from '../../../styles/ui-styles'
import SideBar from '../../SideBar/index'
import TopBar from '../../TopBar/index'
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import Controller from '../../../controllers/Reportes';

import Button from '@material-ui/core/Button';

import ReporteDesglosePuntos from './desglosePuntos';

import jwt from 'jwt-decode';

class RptDesglosePuntos extends Component {
  constructor(props){
    super(props)
    this.state = {
        idLider :'',
        pais :'',
        open:false,
        campanas:[],
        idCampana: 0,
        tipo: 0,
        data:[],
        rows :[],

    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.geDropDowntCampanas = this.geDropDowntCampanas.bind(this)
    this.handleChangeCampanas = this.handleChangeCampanas.bind(this)
    this.handleChangeTipo = this.handleChangeTipo.bind(this)
    this.getPuntos = this.getPuntos.bind(this);
    this.handleMuestraPuntos = this.handleMuestraPuntos.bind(this);
    this.handleMuestraPuntosDet = this.handleMuestraPuntosDet.bind(this);
   }


    
 componentDidMount(){
  const key = localStorage.getItem("Authorization") 
  this.setState({keylocst:key})
  const jwtkey = jwt(key)
  this.setState({idLider:jwtkey.user.id})
  this.setState({pais:jwtkey.user.idPais})

  Controller.fetchDataCampanadesglosePuntos(jwtkey.user.idPais.toString())
  .then(res => {     
    if (res.data.success === true){        
      this.setState({campanas:res.data.data.data})
    }
  })

}

  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }
  
  geDropDowntCampanas(){ 
    if(this.state.campanas.length){   
       return(this.state.campanas.map(campanas => <option key={campanas.idCampana} value={campanas.idCampana}>{campanas.Descripcion}</option>))
    }
  }

  handleChangeCampanas(e){
    this.setState({idCampana: e.target.value});
  }
  handleChangeTipo(e){
    this.setState({tipo: e.target.value});
  }

  getPuntos(){
     Controller.fetchDataDesglosePuntos(this.state.idLider,this.state.idCampana,this.state.tipo,this.state.pais.toString())
    .then(res => {   
      if (res.data.success === true){
        //this.setState({campanas:res.data.data.data})
        this.setState({rows:res.data.data.data});
      }
    })    
  }
 
  handleMuestraPuntos(){  
      return this.handleMuestraPuntosDet(this.state.rows)       
  }

  handleMuestraPuntosDet(data){
    return <ReporteDesglosePuntos rows={data}/>
  }
  
  render(){
      const {classes,onToggleDark} = this.props
      return (     
        <div className={classes.root}>
          <CssBaseline />
          <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
          <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          
             
          <main className={classes.content}>         
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant='h4' component="p">
                Desgloce de Puntos
                </Typography> 
                <div className={classes.appBarSpacer} />
                <Grid container spacing={3}>
                <Grid item>
                    <InputLabel htmlFor="age-native-simple">Campaña:</InputLabel>
                        <Select native required 
                        value = {this.state.idCampana} 
                        onChange={this.handleChangeCampanas}
                        inputProps={{name: 'Campana',id: 'age-native-simple',}}>
                        {this.geDropDowntCampanas()}
                    </Select>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="age-native-simple">Tipo:</InputLabel>
                      <Select native required value={this.state.movimientos} onChange={this.handleChangeTipo} 
                      inputProps={{name: 'Tipo',id: 'age-native-simple',}}>
                        <option key={0} value={0}>{'Propios'}</option>
                        <option key={1} value={1}>{'Directos'}</option>                      
                      </Select>
                  </Grid>

                  <Grid item>
                     <Button variant="outlined" size = 'small' color="primary"  className={classes.buttonpdf} 
                     onClick={this.getPuntos}
                     >Ver</Button>
                       </Grid>
                  
              </Grid>  

              {this.handleMuestraPuntos()}
              {/* <ReporteDesglosePuntos rows={this.state.rows}/>    */}

        
            </Container>  
            </main>

    
        </div>
     
     
     
     )

    }
}

export default withStyles(useStyles)(RptDesglosePuntos)
