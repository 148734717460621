import React, { Component } from "react";
import TablaEmprendedor from '../Table/index';

class edocta extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'Documento', label: 'Documento', minWidth: 100 },
                    { id: 'Fecha', label: 'Fecha', minWidth: 120 },
                    { id: 'Importe', label: 'Importe', minWidth: 100, format: value => value.toFixed(2),},
                    { id: 'Saldo', label: 'Saldo', minWidth: 120, format: value => value.toFixed(2),},
                    { id: 'MontoA', label: 'Monto A', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'MontoB', label: 'Monto B', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'MontoO', label: 'Monto O', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Impuesto', label: 'Impuesto', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Subtipo', label: 'Sub Tipo', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Usuario', label: 'Usuario', minWidth: 120, format: value => value.toFixed(2), },
                  ]}
              />
          );
    }
  }

  export default edocta