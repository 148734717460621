import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  avatar: {
    margin: 10,
  },
  redAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor:'#E62047',
    width: 70,
    height: 70,
  },
}));

export default function CardLider(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
 
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  
  const open = Boolean(anchorEl);
  //const id = open ? 'simple-popover' : undefined;
  let nom = new String(props.data.NomCli);
  let letterA = nom.substr(0,1);

  return (
       
        <div>
          <br/>
          <Grid container justify="center" alignItems="center">
            <Avatar className={classes.redAvatar} >
              <Icon className={clsx(classes.icon, 'fas fa-user-alt')} fontSize="large"/>
            </Avatar>
            <Button variant="outlined" size="small"  color="secondary" className={classes.button} onClick={handleClick}>
              Info
            </Button>

            <Typography component="p" variant="subtitle2" >{props.data.NomCli}</Typography>  
            <Typography component="p" variant="subtitle2"align="center">Categoría: {props.data.categoria}</Typography>
            <Typography component="p" variant="subtitle2"align="center">Descuento: {props.data.infDesc}</Typography>
            <Popover
                //id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                marginThreshold = {16}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }} 
              > 
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.redAvatar}>
                        {letterA}
                      </Avatar>
                    }
                    title={props.data.NomCli}
                    subheader={props.data.categoria}
                  />
                   <CardContent>
                    <Typography component="p" variant="subtitle2"align="left">Email: {props.data.email}</Typography>
                    <Typography component="p" variant="subtitle2"align="left">Patrocinador: {props.data.NomPat} </Typography>
                    <Typography component="p" variant="subtitle2"align="left">Cdz: {props.data.Cdz}</Typography>
                  </CardContent>
                </Card>
              </Popover>
          </Grid>
          <br/>
          <Divider />

        </div>
  );
}