import React, {Component} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';

import useStyles from '../../styles/ui-styles';
import SideBar from '../SideBar/index';
import TopBar from '../TopBar/index';
import Button from '@material-ui/core/Button';
import GetApp from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import jwt from 'jwt-decode';
import CatalogoPDF from '../../config/general'
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Catalogo extends Component {
  constructor(props){
    super(props)
    this.state = {
      open:false,
      numPages: null,
      pageNumber: 1,
      pais :'', 
      filepdf :'', 
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this); 
  }


  componentDidMount(){
    const key = localStorage.getItem("Authorization")
    const jwtkey = jwt(key)
    this.setState({pais:jwtkey.user.idPais})
    this.setState({filepdf : CatalogoPDF[jwtkey.user.idPais-1].Catalogo+'/Catalogo.pdf'})
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

  goToPrevPage = () =>{
    if(this.state.pageNumber !== 1 ){
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    }
  }
    
  goToNextPage = () =>{
    if(this.state.pageNumber !== this.state.numPages ){
      if(this.state.pageNumber <= this.state.numPages ){
        this.setState(state => ({ pageNumber: state.pageNumber + 1 }))
      }
    }
  }

    render(){
      const {classes,onToggleDark} = this.props;
      const { pageNumber, numPages } = this.state;
      
      
      return (     
        <div className={classes.root}>
          <CssBaseline />
          <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
          <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          <main className={classes.content}>
              <div className={classes.appBarSpacer} />  
              <div className={classes.appBarSpacer} />  
              <Grid container className={classes.root} >
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                 
                      <Button color="primary" title="Anterior" variant="outlined" size='small'  className={classes.buttonpdf}>     
                          <SkipPreviousIcon  style={{ fontSize: 38, }} onClick={this.goToPrevPage} />
                      </Button>
                       <Button color="primary" title="Siguiente" variant="outlined" size='small'  className={classes.buttonpdf}>   
                         <SkipNextIcon  style={{ fontSize: 38, }} onClick={this.goToNextPage}/>
                      </Button>    

                  <Link href={this.state.filepdf} target="_blank" rel="noreferrer" underline="none">
                  <Button variant="outlined" size = 'large' color="primary" className={classes.buttonpdf}>
                        Descargar
                        <GetApp className={classes.rightIcon} />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12}>           
                <Grid container justify="normal" spacing={5}>
                    <Grid item>
                        <Document file = {this.state.filepdf} onLoadSuccess={this.onDocumentLoadSuccess} className={classes.container}>
                        <Page pageNumber={pageNumber} />
                        <p >Page {pageNumber} of {numPages}</p>
                        </Document> 
                    </Grid>
                  </Grid>        
                </Grid>
            </Grid>
      
          </main>  
 
        </div>
      )
    }
}

export default withStyles(useStyles) (Catalogo)