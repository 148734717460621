import React from 'react';

function SelectAnio(){    

    let name = 'Seleccione..'
    let today = new Date();
    
    const lista = []
    lista.push( {"id": 0, "nombre": name},)  
    for(let year = 2015; year <= today.getFullYear(); year++){       
        lista.push( {"id": year, "nombre": year},)
    }
    
    return(lista.map(anio => <option key={anio.id} value={anio.id}>{anio.nombre}</option>))
}

export default SelectAnio;