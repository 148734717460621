import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ZmtSideBar from '../../SideBar/index';
import ZmtTopBar from '../../TopBar/index';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import ReporteCumpleanios from './cumpleanios';
import Controller from '../../../controllers/Reportes';
import SelectMes from '../../SelectMes/index';
import lstMeses from '../../SelectMes/MesList';
import Typography from '@material-ui/core/Typography';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles'

class RptCumpleanios extends Component{

  constructor(props){
    super(props)
    this.state = {
      open:false, 
      mes: '',
      idLider :0,
      pais :'', 
      rows :[],
      meses: lstMeses, 
      response: false
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleChangeMes = this.handleChangeMes.bind(this);
    this.getCumpleanios = this.getCumpleanios.bind(this);
  }

  componentDidMount(){
    const key = localStorage.getItem("Authorization")
 
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({idLider:jwtkey.user.id});
    this.setState({pais:jwtkey.user.idPais});

    if(this.state.mes !== 0){
      setTimeout(()=>{this.getCumpleanios(this.state.mes);}, 1200);
    }
  }

  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

  handleChangeMes(e){
    this.setState({mes: e.target.value})
    this.getCumpleanios(e.target.value);
  }

  getCumpleanios(mes){
      const idLider = this.state.idLider;
      const pais = this.state.pais;
      const data = Controller.fetchDataCumpleanios(idLider, mes, pais.toString())
      data.then(res=>{   
        this.setState({rows:res.data.data.cumpleanios});
      }).catch(e=>{
        this.setState({rows:[]});
      })
  }



    render(){
        const {classes} = this.props
        return (
            <div className={classes.root}>
          <CssBaseline />
          <ZmtTopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen}/>
          <ZmtSideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />          
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant='h4' component="p">
                 Cumpleaños por mes
                </Typography> 

                <div className={classes.appBarSpacer} />
         
                <InputLabel htmlFor="age-native-simple">Mes:</InputLabel>
                <Select native required value={this.state.mes} onChange={this.handleChangeMes} inputProps={{name: 'Mes',id: 'age-native-simple',}}>
                  <SelectMes meses ={this.state.meses} />
                </Select>
                <div className={classes.appBarSpacer} />
                <ReporteCumpleanios rows={this.state.rows}/>
                <div className={classes.appBarSpacer} />  
                <div className={classes.appBarSpacer} />  
            </Container>        
          </main>

        </div>
          );
    }
}
export default (withStyles)(useStyles) (RptCumpleanios);