import React, {Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


import useStyles from '../../../styles/ui-styles'
import ZmtSideBar from '../../SideBar/index'
import ZmtTopBar from '../../TopBar/index'
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import Controller from '../../../controllers/Reportes';

import SelectMes from '../../SelectMes/index';
import lstMeses from '../../SelectMes/MesList';
import SelectAnio from '../../SelectAnio/index';

import ReporteVtaxPat from './ventasporpat';
import ReporteVtaxPatDet from './ventasporpatdet';

import jwt from 'jwt-decode';

class RptVtaxPatrocinador extends Component {
  constructor(props){
    super(props)
    this.state = {
      idLider :'',
        pais :'',
        open:false,
        meses: lstMeses, 
        mes:'',
       
        anio:'',
        rows :[],
        rowsdet :[],
        tipo:0,



    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleChangeMes = this.handleChangeMes.bind(this)
    this.handleChangeAnio = this.handleChangeAnio.bind(this)
    this.getVtaxPatro = this.getVtaxPatro.bind(this)
    this.handleChangeTipo = this.handleChangeTipo.bind(this)

  }
  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }
  
  handleChangeMes(e){
    this.setState({rows:[]});
    this.setState({mes: e.target.value})
    if (this.state.anio){
      this.getVtaxPatro(e.target.value.toString(),this.state.anio.toString());
    }

  }
  handleChangeAnio(e){
    this.setState({rows:[]});
    const anioSelected = e.target.value.toString()
    this.setState({anio: anioSelected})    
    if (this.state.mes){
      this.getVtaxPatro(this.state.mes.toString(),anioSelected);
    }
  }

  handleChangeTipo(e){
    this.setState({tipo: e.target.value});
  }
 
  getVtaxPatro(mes,anio){
    const idLider = this.state.idLider;
    const pais = this.state.pais;
    Controller.fetchDataVtaxPatro(idLider, mes,anio, pais.toString())
      .then(res=>{   
      if (!res.data.message){
        this.setState({rows:res.data.data.ventas});
      }       
     })
     Controller.fetchDataVtaxPatroDet(idLider, mes,anio, pais.toString())
     .then(res=>{   
     if (!res.data.message){
       this.setState({rowsdet:res.data.data.ventas});
     }       
    })
}

 componentDidMount(){
    const key = localStorage.getItem("Authorization")
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({idLider:jwtkey.user.id})
    this.setState({pais:jwtkey.user.idPais})
  }

    render(){
      const {classes} = this.props 

      return (     
        <div className={classes.root}>
          <CssBaseline />
          <ZmtTopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen}/>
          <ZmtSideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          
             
          <main className={classes.content}>
         
            <div className={classes.appBarSpacer} />

            <Container maxWidth="xl" className={classes.container}>

                <Typography variant='h4' component="p">
                  Ventas por patrocinador
                </Typography> 
                <div className={classes.appBarSpacer} />

                <Grid container spacing={3}>
                <Grid item>
                    <InputLabel htmlFor="age-native-simple">Mes:</InputLabel>
                        <Select native required value={this.state.mes} onChange={this.handleChangeMes} inputProps={{name: 'Mes',id: 'age-native-simple',}}>
                          <SelectMes meses ={this.state.meses} />
                    </Select>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="age-native-simple">Año:</InputLabel>
                    <Select native required value={this.state.anio} onChange={this.handleChangeAnio} inputProps={{name: 'Año',id: 'age-native-simple',}}>
                      <SelectAnio />
                  </Select>
                </Grid>  

                     <Grid item>
                  <InputLabel htmlFor="age-native-simple">Tipo:</InputLabel>
                      <Select native required value={this.state.movimientos} onChange={this.handleChangeTipo} 
                      inputProps={{name: 'Tipo',id: 'age-native-simple',}}>
                        <option key={0} value={0}>{'Agrupado'}</option>
                        <option key={1} value={1}>{'Detallado'}</option>                      
                      </Select>
                  </Grid>

              </Grid>  
                <div className={classes.appBarSpacer} />

               {this.state.tipo == 0 &&  <ReporteVtaxPat rows={this.state.rows}/>}
              
               {this.state.tipo == 1 &&  <ReporteVtaxPatDet rows={this.state.rowsdet}/>}
            
       
                <div className={classes.appBarSpacer} />  
                <div className={classes.appBarSpacer} />  
            </Container>       
          </main>

        </div>
      )

    }
}

export default withStyles(useStyles)(RptVtaxPatrocinador)