import React, { Component } from 'react';

import {withStyles} from '@material-ui/core/styles'
import useStyles from '../../../styles/ui-styles'

import Card from '@material-ui/core/Card'

import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import Grid from '@material-ui/core/Grid'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import Loading from '../../Loading/index'


class Producto extends Component{
    constructor(props){
        super(props)
        this.state = {       
          qtySol:1,
          load:false,
          confirmOpen:false,
          transition:undefined,          
        };
        this.addQty = this.addQty.bind(this);      
        this.removeQty = this.removeQty.bind(this);  
        this.clickHandler = this.clickHandler.bind(this);   
        
    } 


    addQty = ()=>{
        this.setState({qtySol:this.state.qtySol+1})
    }
    removeQty = ()=>{
        if(this.state.qtySol > 1){
            this.setState({qtySol:this.state.qtySol-1})
        }
    }
    clickHandler(){
        this.setState({load:true})
        this.props.addProducto(this.props.Codigo,this.state.qtySol)
        setTimeout(()=>{
            this.setState({load:false})       
            this.setState({qtySol:1})  
        }, 500)  
    }
    render(){

        const {classes} = this.props
        
        return ( 
            
            <div>
                {this.state.load && <Loading/>}
                {!this.state.load && 
                <Card >

                <CardContent className={classes.CardContent}> 

                    <Typography   variant="body1"  align="center"> 
                        {this.props.Codigo}
                    </Typography>

                    <Grid item xs={12} align ='center'> 
                        <img src= {this.props.Imagen}
                        onError={(e)=>{e.target.src= 'https://zermatinternal.com:3030/ImagenesPaises/img/Catalogo/NoDisponible.png'}}
                        title=  {this.props.Descripcion}
                        alt=  {this.props.Descripcion}
                        width= "150px"   height= "150px"   
                        />
                    </Grid>   
                      
                    <Grid container >
                        <Grid item xs >                           
                            <Typography  noWrap variant="body1"  align="center"> 
                                {this.props.Descripcion}
                            </Typography>                              
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item xs >
                            <Typography  variant="h5"  align="center">
                                {this.props.Moneda} {this.props.Precio}
                            </Typography>                    
                        </Grid>
                    </Grid>
                </CardContent>              
              
                <CardActions className={classes.CardActions}> 
                    <Grid container alignItems="center" justify="center" >
                        <Grid item xs={3} > 
                            <Button  onClick={this.removeQty}  
                                size='small'  color="primary" 
                                 className={classes.buttonQty}
                                >  
                                <Typography  variant="h5" >-</Typography> 
                            </Button>    
                        </Grid>
                        <Grid item xs={3} > 
                            <TextField
                            // id="outlined-select-currency-native"
                                id="outlined-margin-dense"
                                className={classes.textFieldQty}
                                value={this.state.qtySol}
                                margin="dense"
                                variant="outlined"
                                align="center"
                                readOnly               
                                >
                            </TextField> 
                        </Grid>
                        <Grid item xs={3} >                            
                            <Button  onClick={this.addQty}  
                            size='small'  color="primary" 
                           className={classes.buttonQty}
                           >  
                            <Typography  variant="h5" >+</Typography>                        
                            </Button> 
                        </Grid>

                        <Grid item xs={3}  >   
                            <Button   color="primary" 
                              className={classes.buttonQty} title="Agregar">                       
                                <AddShoppingCartIcon color="inherit" 
                                className={classes.iconAddTienda}
                                style={{ fontSize: 35, color:'#4caf50' }}
                                                                        onClick={this.clickHandler}/> 
                            
                            </Button>  
                        </Grid>
                    </Grid> 
                </CardActions>          

              </Card>  
                }
          

             
        </div>
       
    
        )
    }
}

export default (withStyles(useStyles)(Producto))
