import API from '../api/Pedidos';

const Controller = {
    fetchPedidoNumItems: async(pedido,pais) =>{
        return await API.getPedidoNumItems(pedido,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.data.pedido[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchPedido: async(pedido,pais) =>{
         return await API.getPedido(pedido,pais)     
        .then(resp => {  
            if (resp.data.success) {       
                return  resp.data.data.pedido
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchdeletePedidoDet: async(pais,pedido,codigo,tipcom,idpromo,idpromomx,cset) =>{   

        return await API.deletePedidoDet(pais,pedido,codigo,tipcom,idpromo,idpromomx,cset)     
       .then(resp => {  
           if (resp.data.success) {       
               return  resp.data.data.eliminado
           }  
       })
       .catch(err =>{        
           return err        
       })            
   },
   fetchpostInsPedidoCab: async(lider,pais,idcdz) =>{         
    return await API.postInsPedidoCab(lider,pais,idcdz)     
   .then(resp => {        
            if (resp.data.success) {       
             return  resp.data.pedido[0].idPedido
            }  
        })
        .catch(err =>{     
            return err        
        })            
   },
   fetchpostInsPedidoDet: async(pedido,codigo,qty,pais) =>{        
    return await API.postInsPedidoDet(pedido,codigo,qty,pais)     
   .then(resp => {        
            if (resp.data.success) {       
             return  resp.data.pedido[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
   },
   fetchCancelaPedido: async(pedido,pais) =>{
        return await API.putCancelaPedido(pedido,pais)     
    .then(resp => {  
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
   },
   fetchpostCerrarPedido: async(pedido,pais) =>{        
        return await API.postCerrarPedido(pedido,pais)     
    .then(resp => { 
             if (resp.data.success) {       
              return  resp.data.factura[0]
             }  
         })
         .catch(err =>{        
             return err        
         })            
    },

    fetchgetRemision: async(pedido,pais) =>{
        return await API.getRemision(pedido,pais)     
    .then(resp => {  
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
   },
    
   fetchpostEnviarPedido: async(pedido,pais) =>{        
    return await API.postEnviarPedido(pedido,pais)     
    .then(resp => { 
         if (resp.data.success) {       
          return  resp.data.factura[0]
         }  
     })
     .catch(err =>{        
         return err        
     })            
    },
    fetchpostEnviarNotificacionPedido: async(pedido,moneda,pais) =>{        
        return await API.postEnviarNotificacionPedido(pedido,moneda,pais)     
        .then(resp => { 
             if (resp.data.success) {       
              return  resp.data.factura[0]
             }  
         })
         .catch(err =>{        
             return err        
         })            
    },

    fetchgetActualizaDatos: async(lider,pais) =>{         
    return await API.getActualizaDatos(lider,pais)     
    .then(resp => { 
            if (resp.data.success) {       
            return  resp.data.Actualizacion[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },

   fetchputActualizaDatos: async(lider,pais,email,telcel) =>{   
       
    return await API.putActualizaDatos(lider,pais,email,telcel)     
    .then(resp => { 
        
         if (resp.data.success) {       
          return  resp.data.estatus[0]
         }  
     })
     .catch(err =>{        
         return err        
     })            
    },

    fetchgetValidaEstatusPedido: async(pedido,pais) =>{   
    return await API.getValidaEstatusPedido(pedido,pais)     
    .then(resp => {       
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
   },

   fetchgetSegPedidos: async(lider,pais) =>{
        return await API.getSegPedidos(lider,pais)     
        .then(resp => {  
                if (resp.data.success) {       
                return  resp.data.data.Pedidos
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchgetSegPedidosDet: async(pedido,pais) =>{ 
        return await API.getSegPedidosDet(pedido,pais)     
        .then(resp => {  
                if (resp.data.success) {       
                return  resp.data.data.Pedido
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },


}

  
export default Controller


