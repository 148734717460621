import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../styles/ui-styles';

import Container from '@material-ui/core/Container';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';



import TextField from '@material-ui/core/TextField'


import controller from '../../controllers/Pedidos';
import controllerLog from '../../controllers/Login';

class DatosLider extends Component{

    constructor(props){
        super(props)
        this.state = {
            open:true,
           // idLider: '',
     
           dialogError:false,  
           mensaje: '',    
        };

        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);

        this.clickHandlerSend = this.clickHandlerSend.bind(this); 

    }

    handleDrawerOpen = () => {
        this.setState({open:true})
    }
    handleDrawerClose = () => {
        this.setState({open:false})
    }

    clickHandlerSend = () => { 
       
        var email = document.getElementById("id_email").value
        var telcel = document.getElementById("id_celular").value

        /*valida email y celular*/     
        if(email && telcel ){   

            /*actualiza informacion*/                  
            controller.fetchputActualizaDatos(this.props.Lider,this.props.Pais,email,telcel)
            .then(res=>{     
                if(res.Actualizado === 1){                   
                    this.setState({mensaje:'Se actualizaron los datos correctamente'})
                    this.setState({dialogError:true})
                    setTimeout(()=>{         
                        this.setState({dialogError:false})
                    }, 1000) 
                        /*obtiene nuevamente el token*/
                        controllerLog.fetchToken(this.props.Lider,this.props.Pais.toString())
                        .then(respTk =>{   
                            if (respTk.data.success === true){  
                                window.localStorage.clear()
                                window.localStorage.setItem('Authorization', respTk.data.data)                              
                                //this.props.history.push(`/Tienda`)
                                window.location = '/Tienda'
                            }else{
                                this.setState({mensaje:'Ocurrió un error, por favor vuelve a intentar'})
                                this.setState({dialogError:true})
                                setTimeout(()=>{         
                                    this.setState({dialogError:false})
                                }, 1000)                          
                            }

                        })
                }
                else{
                    this.setState({mensaje:'Ocurrió un error, por favor vuelve a intentar'})
                    this.setState({dialogError:true})
                    setTimeout(()=>{         
                        this.setState({dialogError:false})
                    }, 1000) 
                }

            })
            .catch(e=>{
         
                this.setState({mensaje:'Informacion Incorrecta'})
                this.setState({dialogError:true})
                setTimeout(()=>{         
                    this.setState({dialogError:false})
                }, 1000) 
            })

            
        }else{

            this.setState({mensaje:'Debe ingresar la informacion completa'})
            this.setState({dialogError:true})
            setTimeout(()=>{         
                this.setState({dialogError:false})
            }, 1000) 

        }


    }



    render(){
        const {classes} = this.props

        return (
            <div>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />  
                    <Container maxWidth="sm" className={classes.container}>
                        {/* <Paper className={classes.paperPed}>               */}
                            <Grid container   spacing={1} alignItems="center" justify="center"  >
                                <Grid item  xs = {12}>
                                    <Typography variant='h4' component="p">
                                            Actualiza tus datos:
                                    </Typography>
                                </Grid>
                    
                                <Grid item xs = {12} >
                                    <TextField 
                                    id="id_email"
                                        label="Correo"                                   
                                        //onChange={this.handleChangeCodigo}
                                        margin="normal"
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.textFielDataClie}
                                        />                       
                                </Grid>

                                <Grid item xs = {12} >
                                    <TextField
                                    id="id_celular"
                                        label="Movil"                
                                        //onChange={this.handleChangeCodigo}
                                        margin="normal"
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.textFielDataClie}
                                        />                       
                                </Grid>

                                <Grid item xs = {12} >
                                    <Button  variant="outlined" size='small'  color="secondary" 
                                        onClick={this.clickHandlerSend} 
                                        title="Enviar" 
                                        className={classes.btnDataClie}
                                        >    
                                        {/* <Icon className={clsx(classes.icon, 'far fa-paper-plane')} style={{ fontSize: 25 }}  /> 
                                            */}
                                             <Typography  variant='subtitle1'>Actualizar</Typography>     
                                    </Button>  
                              
                                </Grid>


                            </Grid>    
                           

                        {/* </Paper> */}


                        <Dialog open={this.state.dialogError} >
                        <DialogContent>
                            <DialogContentText>
                                {this.state.mensaje}
                            </DialogContentText>
                        </DialogContent>
                        </Dialog>

                
                    
            
                        </Container> 
                </main>            
            </div>
   
    )
    }
}

export default (withStyles(useStyles)(DatosLider))
