import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import useStyles from './ui-styles';
import {withStyles} from '@material-ui/core/styles';


import SelectPais from '../SelectPais/index';
import lstPaises from './dbname';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import Ip from '../../controllers/Ip';
import Bowser from 'bowser';

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import ControllerLog from '../../controllers/Login'
import ControllerPed from '../../controllers/Pedidos'

class Login extends Component {

  constructor(props){
    super(props);
    this.state=({
      usuario: '',
      password: '', 
      pais: 0, 
      token: '',
      acceso: true, 
      propUst : false, 
      propPwd : false, 
      propPais : false, 
      paises: lstPaises, 
      message : '', 
      open : false,
      ipPub:'',
      platform:'',
      os:'',
      browser:'',
      dialogSesion :false,

    })
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validarDatos = this.validarDatos.bind(this);
    this.handleMsgClick = this.handleMsgClick.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleConfirmDialog = this.handleConfirmDialog.bind(this)
  }

  handleChange(e){
    const prop = e.target.name;
    this.setState({[prop]: e.target.value, message:''});
    
  }




  componentDidMount(){

    Ip.ctrlgetIp()
    .then(resIP =>{  
      this.setState({ipPub:resIP}) 
    })
    .catch(()=>{
      this.setState({ipPub:'1.1.1.1'}) 
    })

    const browser = Bowser.getParser(window.navigator.userAgent)
    this.setState({ 
      platform:browser.parsedResult.platform.type,
      os:browser.parsedResult.os.name,
      browser:browser.parsedResult.browser.name})
  }

  validarDatos(){
    if(this.state.usuario !=='' && this.state.password !== '' && this.state.pais !== 0) {
      this.setState({propUst:false, propPwd:false, propPais:false})
      return true
    }
    else{
      if(this.state.usuario === ''){
        this.setState({propUst:true})
      }else {this.setState({propUst:false})}
      if(this.state.password === ''){
        this.setState({propPwd:true})
      }else{this.setState({propPwd:false})}
      if(this.state.pais === 0){
        this.setState({propPais:true})
      }else{this.setState({propPais:false})}
    }
  }

  handleMsgClick() {
    if (this.state.message){
      alert(this.state.message);
    }
  }

  handleSubmit(e){
    e.preventDefault();

    if(this.validarDatos()) {
      /*valida si existe una sesion activa */
      ControllerLog.fetchValidaSesionLg(this.state.usuario.toString(),this.state.pais.toString())
      .then(resp =>{   
           /*si regresa 0 no hay sesion activa sigue con el proceso de ingreso a la aplicacion*/
          if(resp.data.data.Estatus[0].Estatus === 0){
              /*valida los datos de acceso son correctos*/       
              const data = ControllerLog.fetchDataLogin(this.state.usuario, this.state.password, this.state.pais
                ,this.state.ipPub,this.state.platform,this.state.os,this.state.browser);
              data.then(resp =>{   
                  /*genera token y accede al sistema*/     
                  if (resp.data.success === true){
                    this.setState({token:resp.data.data, acceso: true });
                    window.localStorage.setItem('Authorization', resp.data.data)
                      this.props.history.push(`/Dashboard`)
                }else{
                  this.setState({message:resp.data.message, acceso: false });
                }
              }).catch(e=>{
                  this.setState({message:"Acceso denegado", acceso: false });
              })

          }else{          
            this.setState({dialogSesion:true})      
          }
      })
      .catch(()=>{
        this.setState({message:"Intenta nuevamente", acceso: false });
      })
    }
  }


  handleCloseDialog = () => {
    this.setState({dialogSesion:false})
  }

  handleConfirmDialog =() =>{
    
    this.setState({dialogSesion:false})

      /*Finaliza la sesion del token anterior*/
      ControllerLog.fetchCierraSesionLg(this.state.usuario.toString(),this.state.pais.toString())
      .then(resp =>{  
         /*Si existe un pedido lo Cancela*/
         if(localStorage.getItem("Pedido")){
          ControllerPed.fetchCancelaPedido(localStorage.getItem("Pedido"),this.state.pais.toString())
          .then(resp =>{
             /*limpia local storage*/
              window.localStorage.clear()
          })
         }      
          /*valida los datos de acceso son correctos*/
          ControllerLog.fetchDataLogin(this.state.usuario, this.state.password, this.state.pais
            ,this.state.ipPub,this.state.platform,this.state.os,this.state.browser)
          .then(resp =>{   
              /*genera token y accede al sistema*/     
              if (resp.data.success === true){
                this.setState({token:resp.data.data, acceso: true });
                window.localStorage.setItem('Authorization', resp.data.data)
                  this.props.history.push(`/Dashboard`)
            }else{
              this.setState({message:resp.data.message, acceso: false });
            }
          }).catch(e=>{
              this.setState({message:"Acceso denegado", acceso: false });
          }) 
      })  

  }

  
  render(){
    const {classes} = this.props;
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      
      <div className={classes.paper}>
        <Avatar className={classes.avatar} title = 'Zermat Internacional'>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" >
          Líderes Zermat
        </Typography> 
        <div>
          {this.state.message === 'usuario o contraseña incorrecta' && <Typography color = "secondary" component="h1" variant="h5">
          Usuario o contraseña incorrecta
        </Typography>}
        </div> 
        <form className={classes.form}  onSubmit={this.handleSubmit} noValidate>
          <TextField variant="outlined" className={classes.margin} required fullWidth onChange ={this.handleChange} label="Usuario" id ="usuario" name="usuario" autoComplete="Usuario" autoFocus error={this.state.propUst} title = 'Usuario'/>
          <TextField variant="outlined" className={classes.margin} required fullWidth onChange ={this.handleChange} label="Contraseña" type="password" id="password" name = "password" autoComplete="current-password"  error={this.state.propPwd} title = 'Password'/>
          <Select native variant="outlined" className={classes.margin} onChange ={this.handleChange} required  error={this.state.propPais} fullWidth input={
            <OutlinedInput name="pais" id="outlined-pais-native-simple" />
          }
        >
          <SelectPais paises ={this.state.paises} />
        </Select>

          {/* <Button type="submit" fullWidth variant="outlined" color="secondary" className={classes.submit} >
          <Icon className={classes.rightIcon}>send</Icon>
             Ingresar
          </Button> */}

        <Button type="submit" fullWidth variant="outlined" color="secondary" className={classes.submit} title = 'Ingresar' >
           <LockOpenIcon ></LockOpenIcon>
        Ingresar
        </Button>


        </form>
      </div>

      <footer className={classes.footer}>
        <Container maxWidth="lg" >
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}   {'Zermat Internacional'}   
            {' '+new Date().getFullYear()}      
          </Typography>    
        </Container>
      </footer>
   
   


              <Dialog open={this.state.dialogSesion} onClose={this.handleCloseDialog}     >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  Aviso
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  Ya existe una sesión activa con este usuario en otro dispositivo, si continuas (todos los datos pedidos no enviados serán cancelados).
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialog} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={this.handleConfirmDialog} color="secondary">
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>

   
    </Container>
  );
  }
}

export default withStyles(useStyles)(Login);