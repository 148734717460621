import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import SideBar from '../SideBar/index';
import TopBar from '../TopBar/index';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import RptEdoCta from './edocta';
import Controller from '../../controllers/Edocta';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../styles/ui-styles';

class Edocta extends Component{

  constructor(props){
    super(props)
    this.state = {
      open:false, 
      lider :0,
      pais :'', 
      rows :[],
      cab : [],
      response: false
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.getCabecera = this.getCabecera.bind(this);
  }

  componentDidMount(){
    const key = localStorage.getItem("Authorization")
    
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({lider:jwtkey.user.id})
    this.setState({pais:jwtkey.user.idPais})
    setTimeout(()=>{        
      const data = Controller.fetchDataEdocta(this.state.lider.toString(),this.state.pais.toString())
        data.then(res=>{   
            this.setState({rows:res.data.data.documentos});
        }).then(()=>{
          const cab = Controller.fetchDataSaldo(this.state.lider.toString(),this.state.pais.toString())
          cab.then(resp=>{
            this.setState({cab:resp.data.data.datosGenerales});
          })
        })
    }, 1200);
   
  }
  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }
  getCabecera(classes){
    if(this.state.cab.length >0){
      return(<Card className={classes.card}>
        <CardHeader  
          title={this.state.cab[0].Nombre}
          subheader={'CDZ: ' + this.state.cab[0].IDCDZ + '-' + this.state.cab[0].CDZNom}
        />
        <CardContent>
          <Typography variant="h5" component="p">{'SALDO: ' + this.state.cab[0].Saldo}</Typography>          
        </CardContent>
      </Card>)
    }
  }

  render(){
        const {classes,onToggleDark} = this.props
        return (
          <div className={classes.root}>
            <CssBaseline />
            <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
            <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />                       
            <main className={classes.content}>         
              <div className={classes.appBarSpacer} />
              <Container maxWidth="xl" className={classes.container}>
                  <Typography variant='h4' component="p">
                    Estado de Cuenta
                  </Typography> 
                  <div className={classes.appBarSpacer} />  
                  {this.state.cab.length > 0 && this.getCabecera(classes)}
                  <div className={classes.appBarSpacer} />  
                  <RptEdoCta rows={this.state.rows}/>
              </Container>
              
              <div className={classes.appBarSpacer} />        
              <div className={classes.appBarSpacer} /> 
             
            </main>
 

          </div>
        )
  }
}
export default (withStyles)(useStyles) (Edocta);