import React, { Component } from "react";
import TablaEmprendedor from '../../Table/index';

class ventasporpat extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'IdCliente', label: 'ID Cliente', minWidth: 100 },
                    { id: 'Nombre', label: 'Nombre', minWidth: 120 },
                    { id: 'Total', label: 'Total', minWidth: 100, format: value => value.toFixed(2),},
                    { id: 'Comisionable', label: 'Comisionable', minWidth: 120, format: value => value.toFixed(2),},
                    { id: 'NoCom', label: 'No Com', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Otros', label: 'Otros', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Impuesto', label: 'Impuesto', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'Saldo', label: 'Saldo', minWidth: 120, format: value => value.toFixed(2), },
                  ]}
              />
          );
    }
  }

  export default ventasporpat