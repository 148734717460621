import React, { Component } from "react";
import TablaEmprendedor from '../../Table/index';

class genanciaslider extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'Generacion', label: 'Generación', minWidth: 100 },
                    { id: 'VentaCli', label: 'Ventas', minWidth: 120, format: value => value.toLocaleString(),},
                    { id: 'Porcentaje', label: 'Porcentaje', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'ComiClie', label: 'Comisión', minWidth: 120, format: value => value.toFixed(2), },
                  ]}
              />
          );
    }
  }

  export default genanciaslider