import React, { Component } from 'react';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Loading from '../../Loading/index'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';




class DetallePed extends Component{
    constructor(props){
        super(props)
        this.state = {       
            idPedido:0,
        }
    }   


    
    getPedido(classes){   
        
         if(this.props.lstPedidoDet.length>0){
           return this.props.lstPedidoDet.map(pedido =>       

            <Grid item xs={12} >  
                <Card >
                    <CardContent> 
                        <Typography   variant="body1"  align="center"> 
                                {pedido.Codart}
                        </Typography>

                        <Grid item xs={12} align ='center'>            
                            {/* <img src=  {`/img/Catalogo/${pedido.Codart.toString()}.jpg`}
                            onError={(e)=>{e.target.src= '/img/Catalogo/NoDisponible.png'}} */}
                            <img src=  {`https://zermatinternal.com:3030/ImagenesPaises/img/Catalogo/${pedido.Codart.toString()}.jpg`}
                            onError={(e)=>{e.target.src= 'https://zermatinternal.com:3030/ImagenesPaises/img/Catalogo/NoDisponible.png'}}
                            title=  {pedido.Descripcion}
                            alt=  {pedido.Descripcion}
                            width= "150px"   height= "150px" />        
                        </Grid>
                
                        <Grid item xs={12}  container>   
                                <Typography  noWrap variant="body1"  align="center"> 
                                        {pedido.Descripcion}
                                </Typography> 
                        </Grid>
                        
                        <Paper className={classes.paperCard}>   
                          <Grid container spacing={1}>                     
                            <Grid item xs={12}  container>   
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >Qty: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{pedido.QtyPed}</Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >Cat.: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}{pedido.PreUniIICat}</Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >Desc.: </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" > {pedido.Descuento}  %  </Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                <Typography variant="subtitle1">Precio: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}{pedido.PreUniII}  </Typography> 
                                </Grid>
                            </Grid> 
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                <Typography variant="subtitle1">Total: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}{pedido.MontoLinII}  </Typography> 
                                </Grid>
                            </Grid>  
                        </Grid>
                        </Paper> 
                
                
            
                    </CardContent>
                </Card>  
                <div className={classes.appBarSpacerShort} />   
                <div className={classes.appBarSpacerShort} />   
            </Grid> 

           ) 
         } 
       }

    render(){
        const {classes} = this.props

        return (
           
          
                 <div>                
                    {this.state.load && <Loading/>}
                    {!this.state.load &&  
                        <Grid  container xs={12}  sm = {6}  >  
                            { this.getPedido(classes)}
                        </Grid>    
                      }            
                </div>    
          
        )
    }
}

export default (withStyles(useStyles)(DetallePed))
