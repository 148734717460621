import React, { Component } from "react";
import Tabla from '../../Table/index';

class desglocePuntos extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <Tabla
                rows = {rows}
                columns={ [
                    { id: 'IdCliente', label: 'IdCliente', minWidth: 100 },
                    { id: 'Nombre', label: 'Nombre', minWidth: 120 },
                    { id: 'Periodo', label: 'Periodo', minWidth: 150, format: value => value.toLocaleString(),},
                    { id: 'Monto', label: 'Monto', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'PuntosMonto', label: 'Puntos Monto', minWidth: 120 },
                    { id: 'PuntosTotales', label: 'Puntos Totales', minWidth: 120 },
                    { id: 'PuntosCanjeados', label: 'Puntos Canjeados', minWidth: 120 },
                    { id: 'PuntosRestantes', label: 'Puntos Restantes', minWidth: 120 },

                  ]}
              />
          );
    }
  }

  export default desglocePuntos