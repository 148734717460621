import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ZmtSideBar from '../../SideBar/index';
import ZmtTopBar from '../../TopBar/index';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SelectMes from '../../SelectMes/index';
import lstMeses from '../../SelectMes/MesList';
import SelectAnio from '../../SelectAnio/index';


import Controller from '../../../controllers/Multinivel';
import ReporteDetalle from './gananciaslider';
import ReporteDetallexGen from './gananciasliderxgen';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';
import Typography from '@material-ui/core/Typography';

class RptGananciasMul extends Component{

  constructor(props){
    super(props)
    this.state = {
      open:false, 
      mes: '',
      anio: '',
      idLider :0,
      pais :'', 
      rows :[],
      det:[],
      detxgen:[],
      meses: lstMeses, 
      response: false,
    }

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleChangeMes = this.handleChangeMes.bind(this);
    this.handleChangeAnio = this.handleChangeAnio.bind(this);
    this.getGanancias = this.getGanancias.bind(this);
    this.getCabecera = this.getCabecera.bind(this);

  }

  componentDidMount(){
    const key = localStorage.getItem("Authorization")
  
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({idLider:jwtkey.user.id});
    this.setState({pais:jwtkey.user.idPais});
  }

  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

  handleChangeMes(e){
    this.setState({mes: e.target.value})
  }

  handleChangeAnio(e){
    this.setState({anio: e.target.value});
  }


   getCabecera(classes){
    if(this.state.response === true && this.state.rows.length>0){
      return(<Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {'Lider: ' + this.state.rows[0].Padre + '-' +this.state.rows[0].NombrePadre}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {'Categoría: ' + this.state.rows[0].CatPadre}
          </Typography>
          <Typography variant="body2" component="p">{'Monto A: ' + this.state.rows[0].VentaPadre}</Typography>
          <Typography variant="body2" component="p">{'Comision Personal: ' + this.state.rows[0].ComiPadre}</Typography>
          <Typography variant="body2" component="p">{'Comision Grupo PyC: ' + this.state.rows[0].GrupoPyC}</Typography>
          <Typography variant="body2" component="p">{'----------------------------'}</Typography>
          <Typography variant="body2" component="p">{'Bono Productividad: ' + this.state.rows[0].BonoPruductividad}</Typography>
          <Typography variant="body2" component="p">{'Bono Reclutamientos: ' + this.state.rows[0].BonoReclutamiento}</Typography>
          <Typography variant="body2" component="p">{'Bono Nombramiento: ' + this.state.rows[0].BonoNombramiento}</Typography>
          <Typography variant="body2" component="p">{'----------------------------'}</Typography>
          <Typography variant="body2" component="p">{'Total: ' + this.state.rows[0].Total}</Typography>
        </CardContent>
        {this.state.det.length > 0 && <ReporteDetalle rows = {this.state.det}/>}
        <br />
        <Typography variant="body2" component="p">{'Totales por generación'}</Typography>
        <br /> 
        {this.state.detxgen.length > 0 && <ReporteDetallexGen rows = {this.state.detxgen}/>}

      </Card>)
    }
  }

  getGanancias(){
      if(this.state.mes !== '' && this.state.anio !== ''){
          const data = Controller.fetchDataMultinivel(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString())
          data.then(res=>{   
            if(res.data.data.success === true){         
              this.setState({rows:res.data.data.registros,det:res.data.data.registros, response:true});
            }else{this.setState({rows:[], det:[], response:false});}
          }).catch(e=>{
               this.setState({rows:[], det:[], response:false});
          })
          const dataxgen = Controller.fetchDataMultinivelxGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString())
          dataxgen.then(res=>{   
            if(res.data.data.success === true){         
              this.setState({detxgen:res.data.data.registros, });
            }else{this.setState({detxgen:[]});}
          }).catch(e=>{
               this.setState({detxgen:[]});
          })
      }
  }

    render(){
        const {classes} = this.props
        return (
              <div className={classes.root}>
              <CssBaseline />
              <ZmtTopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen}/>
              <ZmtSideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />          
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                  <Typography variant='h4' component="p">
                    Calculo Multinivel
                  </Typography> 
                    <div className={classes.appBarSpacer} />
                    <Grid container spacing={3}>
                    <Grid item>
                      <InputLabel htmlFor="age-native-simple">Mes:</InputLabel>
                      <Select native required value={this.state.mes} onChange={this.handleChangeMes} inputProps={{name: 'Mes',id: 'age-native-simple',}}>
                        <SelectMes meses ={this.state.meses} />
                      </Select>
                    </Grid>
                    <Grid item><InputLabel htmlFor="age-native-simple">Año:</InputLabel>
                      <Select native required value={this.state.anio} onChange={this.handleChangeAnio} inputProps={{name: 'Año',id: 'age-native-simple',}}>
                        <SelectAnio/>
                      </Select></Grid>
                    <Grid item><Button variant="outlined" size = 'small' color="primary"  className={classes.buttonpdf} onClick={this.getGanancias}>Ver</Button></Grid>
                      <div className={classes.appBarSpacer} />
                    </Grid>
                    <div className={classes.appBarSpacer} />
                    {this.getCabecera(classes)}
                    {/* {this.state.response && <ReporteGanancias rows= {this.state.det}/>} */}

                    <div className={classes.appBarSpacer} />  
                    <div className={classes.appBarSpacer} />  
                </Container>               
              </main>
    
            </div>
          );
    }
}
export default (withStyles)(useStyles) (RptGananciasMul);