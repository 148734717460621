import React, { Component, Fragment } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


import ICONS from "../../config/menuIcon";
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../styles/ui-styles';
import jwt from 'jwt-decode';
import CreaPedido from '../../config/general';

import StepImg from '../../../src/components/StepImg/index'
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StepsAyuda from '../../../src/config/StepsAyuda'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import Pdf from '../Pdf/index'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

import ConfGen from '../../config/general'

import Icon from '@material-ui/core/Icon';

import Divider from '@material-ui/core/Divider';

 class SideList extends Component{

    constructor(props){
        super(props)
        this.state = {
            AccesoPedido:'',
            open:false,
            openShop:false,

            opendialogAyuda:false,
            opendialogTerminos:false,
            opendialogAviso:false,
            pais :'', 
            confGen :'', 
            whatsApp:'',
         
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClickShop = this.handleClickShop.bind(this);
        this.handleCloseDialogAyuda = this.handleCloseDialogAyuda.bind(this);
    }
    componentDidMount(){      


        const key = localStorage.getItem("Authorization") 
        const jwtkey = jwt(key)
        var dateNow = new Date();

        this.setState({pais:jwtkey.user.idPais})
        this.setState({whatsApp:jwtkey.user.wa})
        
        this.setState({confGen : ConfGen[jwtkey.user.idPais-1]})
 
  
        if(jwtkey.exp < (dateNow.getTime()/1000)){
          window.localStorage.clear()           
          window.location = '/'
        }
        this.setState({AccesoPedido : CreaPedido[jwtkey.user.idPais -1].Pedido})

    }
    handleClick() {

        this.state.open === false ? this.setState({open:true}) : this.setState({open:false});
    }
    handleClickShop() {

        this.state.openShop === false ? this.setState({openShop:true}) : this.setState({openShop:false});
    }    
    clickAyuda = () => {     
        this.setState({opendialogAyuda:true}) 
    }
    handleCloseDialogAyuda = () => {
    /*cierra ventana de ayuda*/    
        this.setState({opendialogAyuda:false})     
    }  
    clickTerminos = () => {        
        this.setState({opendialogTerminos:true}) 
    }
    handleCloseDialogTerminos = () => {
        /*cierra ventana de terminos y condiciones*/    
        this.setState({opendialogTerminos:false})     
    } 
    clickAviso = () => {         
        this.setState({opendialogAviso:true}) 
    }
    handleCloseDialogAviso = () => {
        /*cierra ventana de aviso*/    
        this.setState({opendialogAviso:false})     
    }  
    clickWhatsApp = ()=>{
        window.open("https://api.whatsapp.com/send?phone=+"+this.state.whatsApp+"&text=Hola,%20soy%20la%20líder%20"+this.props.data.NomCli+"%20 y mi clave es%20"+this.props.data.Id+", por favor me podrías apoyar.")
    }
    

  render(){

    const {classes,data} = this.props;
    return (

        <Fragment>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
            </ListSubheader>
          }
          className={classes.rootSL}
        >
        <ListItem button component={Link} to={'/Dashboard'}  title="Dashboard">
            <ListItemIcon>
                {ICONS['Dashboard']}
                </ListItemIcon>
            <ListItemText primary={'Dashboard'}  />
        </ListItem>
    
        <ListItem button onClick={this.handleClick} title ='Reportes' >
            <ListItemIcon  title="Reportes">
            {ICONS['Barchart']}
            </ListItemIcon>
            <ListItemText primary="Reportes" />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptReclutamientos'} title="Reclutamientos" >
                        <ListItemIcon>
                            {ICONS['DescriptionIcon']}
                            </ListItemIcon>
                        <ListItemText primary={'Reclutamientos'}  />
                    </ListItem>
                </List>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptEmprendedores'} title="Emprendedores" >
                        <ListItemIcon>
                            {ICONS['DescriptionIcon']}
                            </ListItemIcon>
                        <ListItemText primary={'Emprendedores'}  />
                    </ListItem>
                </List>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptCumpleanios'} title="Cumpleaños">
                        <ListItemIcon>
                            {ICONS['DescriptionIcon']}
                            </ListItemIcon>
                        <ListItemText primary={'Cumpleaños'}  />
                    </ListItem>
                </List>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptCierreLider'} title="Cierre Líderes">
                        <ListItemIcon>
                            {ICONS['DescriptionIcon']}
                            </ListItemIcon>
                        <ListItemText primary={'Cierre Líderes'}  />
                    </ListItem>
                </List>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptGananciasLider'} title="Ganancias Líder">
                        <ListItemIcon>
                            {ICONS['DescriptionIcon']}
                            </ListItemIcon>
                        <ListItemText primary={' Calculo Multinivel'}  />
                    </ListItem>
                 </List>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/RptVentasporPat'} title="Ventas x Patrocinador">
                    <ListItemIcon>
                        {ICONS['DescriptionIcon']}
                        </ListItemIcon>
                    <ListItemText primary={'Ventas por patrocinador'}  />
                </ListItem>
                </List>

                {this.state.pais === '2' && <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to={'/RptDesglosePuntos'} title="Desglose de Puntos">
                            <ListItemIcon>
                                {ICONS['DescriptionIcon']}
                                    </ListItemIcon>
                                <ListItemText primary={'Desglose de Puntos'}  />
                            </ListItem>
                        </List>
                }
                
                {(this.state.pais === '1' ||this.state.pais === '2')  && <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={Link} to={'/RptCreditosLider'} title="Créditos por Líder">
                            <ListItemIcon>
                                {ICONS['DescriptionIcon']}
                                    </ListItemIcon>
                                <ListItemText primary={'Créditos por Líder'}  />
                            </ListItem>
                        </List>
                }

            </Collapse>

                <ListItem button component={Link} to={'/EdoCta'}  title="Estado de cuenta">
                    <ListItemIcon>
                        {ICONS['monetization']}
                        </ListItemIcon>
                    <ListItemText primary={'Edo. Cuenta'}  />
                </ListItem>
                <ListItem button component={Link} to={'/Catalogo'} title="Catalogo">
                    <ListItemIcon>
                        {ICONS['LocalLibrary']}
                        </ListItemIcon>
                    <ListItemText primary={'Catálogo'}  />
                </ListItem>
                <ListItem button component={Link} to={'/Estrategias'} title="Estrategias">
                <ListItemIcon>
                    {ICONS['LocalLibrary']}
                    </ListItemIcon>
                <ListItemText primary={'Estrategias'}  />
            </ListItem>
            
            
                {this.state.AccesoPedido && 
                <ListItem button onClick={this.handleClickShop} title ='Comprar' >
                    <ListItemIcon  title="Comprar">
                    {ICONS['ShoppingCartOutlinedIcon']}
                    </ListItemIcon>
                    <ListItemText primary="Comprar" />
                {this.state.openShop ? <ExpandLess /> : <ExpandMore />}
                </ListItem>}
                <Collapse in={this.state.openShop} timeout="auto" >

                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}  component={Link} to={'/Tienda'} title="Tienda" >
                        <ListItemIcon>
                            {ICONS['storefront']}
                            </ListItemIcon>
                        <ListItemText primary={'Tienda'}  />
                    </ListItem>
                </List>

                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={Link} to={'/Seguimiento'} title="Mis pedidos" >
                        <ListItemIcon>
                            {ICONS['LocalShippingIcon']}
                            </ListItemIcon>
                        <ListItemText primary={'Mis pedidos'}  />
                    </ListItem>
                </List>

                <List component="div" disablePadding>
                   
                   <ListItem button   className={classes.nested} title="Como comprar" >
                        <ListItemIcon>      
                        {ICONS['HelpOutlineIcon']}
                        </ListItemIcon>
                        <ListItemText primary={'Como comprar'} onClick={this.clickAyuda}   />
                    </ListItem>  
                </List>

         
         
            </Collapse>

                {this.state.whatsApp && 
                    <ListItem button  title="WhatsApp" >
                        <ListItemIcon>
                    <Icon className="fab fa-whatsapp"    style={{ color:'#25d366' }}  onClick={this.clickWhatsApp}  />
                    </ListItemIcon>
                        <ListItemText primary={'WhatsApp'}        
                        onClick={this.clickWhatsApp}    
                        />
                    </ListItem>
                }
                <Divider component="li" />

                <ListItem button  title="Términos y Condiciones"  >
                    <ListItemIcon>
                        {ICONS['InfoOutlinedIcon']}
                        </ListItemIcon>
                        <ListItemText primary={'Términos y Condiciones'} onClick={this.clickTerminos}   />
                </ListItem>

                <ListItem button  title="Aviso de privacidad" >
                <ListItemIcon>
                    {ICONS['InfoOutlinedIcon']}
                    </ListItemIcon>
                    <ListItemText primary={'Aviso de privacidad'} onClick={this.clickAviso}   />
            </ListItem>

                  
            </List>


 
          
         <Dialog open={this.state.opendialogAyuda} onClose={this.handleCloseDialogAyuda}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Como comprar
            </DialogTitle>
            <DialogContent>                          
                <StepImg  StepDesktop = {StepsAyuda.tutorialStepsDesktop} StepMovil = {StepsAyuda.tutorialStepsMovil}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDialogAyuda} color="secondary">
                Cerrar
                </Button>
            </DialogActions>
         </Dialog>

         <Dialog open={this.state.opendialogTerminos} onClose={this.handleCloseDialogTerminos} maxWidth ="md" 
         scroll={'paper'}>      
            <DialogTitle >               
               Terminos y condiciones                              
            </DialogTitle>
            <DialogContent   >    
                <Grid container >  
                    <Pdf Pdf={this.state.confGen.Terminos+'/Terminos.pdf'} />   
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDialogTerminos} color="secondary">
                Cerrar
                </Button>
            </DialogActions>
         </Dialog>

         <Dialog open={this.state.opendialogAviso} onClose={this.handleCloseDialogAviso} maxWidth ="md" >     
            <DialogTitle >               
                Aviso de privacidad                       
            </DialogTitle>             
            <DialogContent >         
                <Grid container >     
                        <Pdf Pdf={this.state.confGen.Aviso+'/Aviso.pdf'} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDialogAviso} color="secondary">
                Cerrar
                </Button>
            </DialogActions>
         </Dialog>

         

        </Fragment>
      )
  }
}

export default (withStyles)(useStyles) (SideList);