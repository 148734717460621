import React, { Component } from 'react';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box';

import Loading from '../../Loading/index'

import Button from '@material-ui/core/Button'

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';


class Pedidos extends Component{
    constructor(props){
        super(props)
        this.state = {       
            idPedido:0,
        }
        this.clickHandlerDet = this.clickHandlerDet.bind(this);  
    }   

    clickHandlerDet = () => {   
        this.props.getPedidosDet(this.props.Pedido)  
    }

    render(){
        const {classes} = this.props

        return (
           
                 <div>                
                    {this.state.load && <Loading/>}
                    {!this.state.load &&                 
                        <Paper className={classes}>       
                            <Grid container  spacing={1}  alignItems="center" >  
                                    <Grid item xs={2} sm={2} align="center">
                                        <Typography variant="subtitle2"  > {this.props.Pedido} </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={2} align="center">
                                        <Typography variant="overline" > {this.props.Fecha} </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={2} align="right" >
                                        <Typography variant="overline" > {this.props.Moneda}{this.props.Importe} </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} align="center" >
                                        <Typography > 

                                            {this.props.Estatus === 'Cancelado' && 
                                                <Box color ="red">
                                                    {this.props.Estatus} 
                                                </Box>
                                            }
                                            {this.props.Estatus === 'Pendiente' && 
                                                <Box color ="blue">
                                                    {this.props.Estatus} 
                                                </Box>
                                            }
                                            {this.props.Estatus === 'Facturado' && 
                                                <Box color ="green">
                                                    {this.props.Estatus} 
                                                </Box>
                                            }      
                                        </Typography>
                                    </Grid>
                            
                                    <Grid item xs={1} sm={1} align="center" >
                                        <Button  title="Mostrar" align="center">   
                                            <AssignmentOutlinedIcon color="inherit" 
                                            className={classes.iconPed}
                                            style={{ fontSize: 30, color:'green' }}
                                            onClick={this.clickHandlerDet}
                                            align="center" /> 
                                        </Button>    
                                    </Grid>
                                </Grid>  
                            </Paper>      
                    }            
                </div>       
        )
    }
}

export default (withStyles(useStyles)(Pedidos))
