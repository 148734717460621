import axios from 'axios'
import url from './url'

const Api = {
    getEdocta: async (lider,pais) =>{
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/edocta/documentos', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }, 
    getSaldo: async (lider,pais) =>{
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/edocta/saldo', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }
}
export default Api