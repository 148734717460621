import API from '../api/Multinivel';

const Controller = {
    fetchDataMultinivel: async(lider,mes,anio,pais) =>{   
        return await API.getGanancias(lider,mes, anio, pais)     
        .then(resp => {                    
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    }, 
    fetchDataMultinivelxGen: async(lider,mes,anio,pais) =>{   
        return await API.getGananciasxGen(lider,mes, anio, pais)     
        .then(resp => {                    
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    }, 
    fetchDataCierreCab: async(lider,mes,anio,pais) =>{   
        return await API.getCierreCab(lider,mes, anio, pais)     
        .then(resp => {
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    }, 
    fetchDataCierreDet: async(lider,mes,anio, pais, generacion,movimientos) =>{   
        return await API.getCierreDet(lider,mes, anio, pais, generacion,movimientos)     
        .then(resp => {       
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    }, 
    fetchDataCierreTot: async(lider,mes,anio,pais) =>{   
        return await API.getCierreTot(lider,mes, anio, pais)     
        .then(resp => {            
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    },
    fetchDataCierrexGen: async(lider,mes,anio, pais, generacion,movimientos) =>{   
        return await API.getCierrexGen(lider,mes, anio, pais, generacion,movimientos)     
        .then(resp => {       
            if (resp.data.success) {
                return  resp
            }  
        })
        .catch(err =>{
            return err        
        })            
    }, 
}     
export default Controller


