import React, {Component} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';

import useStyles from '../../../styles/ui-styles'
import SideBar from '../../SideBar/index'
import TopBar from '../../TopBar/index'
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import Controller from '../../../controllers/Reportes';

import SelectMes from '../../SelectMes/index';
import lstMeses from '../../SelectMes/MesList';
import SelectAnio from '../../SelectAnio/index';

import ReporteReclutamientos from './reclutamientos';
import jwt from 'jwt-decode';

class RptReclutamientos extends Component {
  constructor(props){
    super(props)
    this.state = {
      idLider :'',
        pais :'',
        open:false,
        meses: lstMeses, 
        mes:'',
        anio:'',
        rows :[],

    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleChangeMes = this.handleChangeMes.bind(this)
    this.handleChangeAnio = this.handleChangeAnio.bind(this)
    this.getReclutamientos = this.getReclutamientos.bind(this)
  }


    
 componentDidMount(){


  const key = localStorage.getItem("Authorization") 
  this.setState({keylocst:key})
  const jwtkey = jwt(key)
  this.setState({idLider:jwtkey.user.id})
  this.setState({pais:jwtkey.user.idPais})

}


  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }
  
  handleChangeMes(e){
    this.setState({rows:[]});
    this.setState({mes: e.target.value})
    if (this.state.anio){
      this.getReclutamientos(e.target.value.toString(),this.state.anio.toString());
    }

  }
  handleChangeAnio(e){
    this.setState({rows:[]});
    const anioSelected = e.target.value.toString()
    this.setState({anio: anioSelected})    
    if (this.state.mes){
      this.getReclutamientos(this.state.mes.toString(),anioSelected);
    }
  }
 

  getReclutamientos(mes,anio){  
    const idLider = this.state.idLider;
    const pais = this.state.pais;
    const data = Controller.fetchDataReclutamientos(idLider, mes,anio, pais.toString())
      data.then(res=>{
          if (res.data.success === true){
          this.setState({rows:res.data.data.reclutamientos});
        }       

     })
  }



    render(){
      const {classes,onToggleDark} = this.props
      return (     
        <div className={classes.root}>
          <CssBaseline />
          <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
          <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          
             
          <main className={classes.content}>         
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant='h4' component="p">
                  Reclutamientos por patrocinador
                </Typography> 
                <div className={classes.appBarSpacer} />
                <Grid container spacing={3}>
                <Grid item>
                    <InputLabel htmlFor="age-native-simple">Mes:</InputLabel>
                        <Select native required value={this.state.mes} onChange={this.handleChangeMes} inputProps={{name: 'Mes',id: 'age-native-simple',}}>
                          <SelectMes meses ={this.state.meses} />
                    </Select>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="age-native-simple">Año:</InputLabel>
                    <Select native required value={this.state.anio} onChange={this.handleChangeAnio} inputProps={{name: 'Año',id: 'age-native-simple',}}>
                      <SelectAnio/>
                  </Select>
                </Grid>            
              </Grid>  
                <div className={classes.appBarSpacer} />
                <ReporteReclutamientos rows={this.state.rows}/>        
               <div className={classes.appBarSpacer} />  
               <div className={classes.appBarSpacer} />   
            </Container>   
            </main>

    
        </div>
     
     
     
     )

    }
}

export default withStyles(useStyles)(RptReclutamientos)
