import React, { PureComponent } from 'react';

import {
    PieChart, Pie, Cell,
  } from 'recharts';

class ChartPie extends PureComponent {
  
  render() {

    const data = [
        { name: 'Group A', value: this.props.data.PorcentA},
        { name: 'Group B', value: this.props.data.PorcentB},
      ]
    
      const COLORS = [this.props.colorA, this.props.colorB]


    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (    


        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
    }

    return (
        <PieChart width={280} height={145}>
        <Pie
          data={data}
          cx={180}
          cy={60}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={60}
          fill="#8884d8"
          dataKey="value"
        >
          {
            // data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    )
  }
}

export default  ChartPie