import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ZmtSideBar from '../../SideBar/index';
import ZmtTopBar from '../../TopBar/index';
import ReporteEmprendedor from './emprendedores.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import Controller from '../../../controllers/Reportes';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';

class RptEmprendedores extends Component{

  constructor(props){
    super(props)
    this.state = {
      open:false, 
      estatus: '1',
      idLider :0,
      pais :'', 
      rows :[],
      response: false, 
      columns : [],
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleChange = this.handleChange.bind(this); 
    this.getEmprendedores = this.getEmprendedores.bind(this);
  }

  componentDidMount(){
    const key = localStorage.getItem("Authorization")
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({idLider:jwtkey.user.id});
    this.setState({pais:jwtkey.user.idPais});

    setTimeout(()=>{this.getEmprendedores(this.state.estatus);}, 1200)    
  }

  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

  handleChange(event){
    this.setState({estatus: event.target.value});
    this.getEmprendedores(event.target.value);
  }

  getEmprendedores(estatus){
      const idLider = this.state.idLider;
      const pais = this.state.pais;
      const data = Controller.fetchDataEmprendedores(idLider, estatus, pais.toString())
      data.then(res=>{   
        this.setState({rows:res.data.data.emprendedores});
      }).catch(e=>{
        this.setState({rows:[]});
      })
  }



    render(){
        const {classes,onToggleDark} = this.props
        return (
            <div className={classes.root}>
          <CssBaseline />
          <ZmtTopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
          <ZmtSideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />          
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant='h4' component="p">
                  Catálogo de Emprendedoras por estatus
                </Typography>
                <RadioGroup aria-label="gender" name="estatus" className={classes.group} value={this.state.estatus} onChange={this.handleChange}>
                  <FormControlLabel value="1" control={<Radio />} label="Activo" />
                  <FormControlLabel value="0" control={<Radio />} label="Inactivo" />
                </RadioGroup>
                {/* <Emprendedores rows={this.state.rows}/> */}
                <ReporteEmprendedor rows={this.state.rows}/>
                <div className={classes.appBarSpacer} />  
                <div className={classes.appBarSpacer} />  
            </Container>
          </main>

        </div>
          );
    }
}
export default (withStyles)(useStyles) (RptEmprendedores);