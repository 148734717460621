import React, { Component } from 'react'

import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/PowerSettingsNew';

import Icon from '@material-ui/core/Icon';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Badge from '@material-ui/core/Badge';

import Redes from '../../config/general'
import AccPedido from '../../config/general'
import Controller from '../../controllers/Pedidos'
import ControllerLog from '../../controllers/Login'
import jwt from 'jwt-decode';
import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';

import Imagenes from '../../config/general'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'


class TopBar extends Component {

    constructor(props){
        super(props)
        this.state = {
          fb:'',
          tw:'',
          ins:'',
          AccesoPedido:'',
          pais :'',
          urlImg :'',

          dialogSesion:false,

        }
        this.clickLogOut = this.clickLogOut.bind(this)
    }
    
    clickLogOut(){

      const key = localStorage.getItem("Authorization")
      const jwtkey = jwt(key) 

      /*Si existe un pedido lo cancela*/
      if(localStorage.getItem("Pedido")){   
         /*Finaliza la sesion del token*/
         ControllerLog.fetchCierraSesionTk(jwtkey.user.id.toString() ,jwtkey.user.idPais.toString())
         .then(resp =>{      
          /*Cancela pedido*/
           Controller.fetchCancelaPedido(localStorage.getItem("Pedido") ,jwtkey.user.idPais.toString())
           .then(resp =>{      
             window.localStorage.clear()      
             window.location='/'
           })
         })    
      }else{
         /*Finaliza la sesion del token*/
         ControllerLog.fetchCierraSesionTk(jwtkey.user.id.toString() ,jwtkey.user.idPais.toString())
         .then(resp =>{      
           window.localStorage.clear()  
           window.location='/'
         })   
      }
    }
  
    componentDidMount(){

      if(!localStorage.getItem("Authorization")){
        window.localStorage.clear()
        window.location='/'
      }else{
        const key = localStorage.getItem("Authorization")
        const jwtkey = jwt(key)
        this.setState({pais:jwtkey.user.idPais})
        this.setState({urlImg : Imagenes[jwtkey.user.idPais-1].Imagenes+'/img/Flags/'+jwtkey.user.idPais+'.png'})
        /*valida si existe una sesion activa */
        ControllerLog.fetchValidaSesionTk(jwtkey.user.id.toString(),jwtkey.user.idPais.toString())
        .then(resp =>{   
          /*si regresa 0 no hay sesion activa*/
          if(resp.data.data.Estatus[0].Estatus === 0){
              /*Si existe un pedido lo cancela*/
              const pedido = localStorage.getItem("Pedido")
              if (pedido){
              /*Finaliza la sesion del token*/
              ControllerLog.fetchCierraSesionTk(jwtkey.user.id.toString() ,jwtkey.user.idPais.toString())
              .then(resp =>{      
                  /*Cancela pedido*/
                  Controller.fetchCancelaPedido(pedido ,jwtkey.user.idPais.toString())
                  .then(resp =>{
                      this.setState({dialogSesion:true})        
                      setTimeout(()=>{         
                          this.setState({dialogSesion:false})
                          window.localStorage.clear()
                          window.location='/'
                      }, 2000)   
                  })
              })  
              }else{
                /*Finaliza la sesion del token*/
                ControllerLog.fetchCierraSesionTk(jwtkey.user.id.toString() ,jwtkey.user.idPais.toString())
                .then(resp =>{   
                      this.setState({dialogSesion:true})        
                      setTimeout(()=>{         
                          this.setState({dialogSesion:false})
                          window.localStorage.clear()
                          window.location='/'
                      }, 2000)   
                }) 
              }

          }else{
            /*asigna valores de estados de en la sesion*/
            this.setState({fb : Redes[jwtkey.user.idPais -1].fb,tw : Redes[jwtkey.user.idPais -1].tw,ins : Redes[jwtkey.user.idPais -1].in})
            this.setState({AccesoPedido : AccPedido[jwtkey.user.idPais -1].Pedido})    

          }
        })

  
      }

    }

    render() {
        const {classes,open,handleDrawerOpen,onToggleDark,numItems,muestraItems} =  this.props
        return (

            <div>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} >
            <Toolbar className={classes.toolbar}>

              <IconButton edge="start" aria-label="open drawer" color="inherit"  onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)} title ='Menu' >
                <MenuIcon />
              </IconButton>
              <Grid item xs={12}  container  alignItems="center"  spacing={1} >
                <Grid item xs={6} sm = {10}  >
                  <img src={this.state.urlImg} 
                  alt={this.state.urlImg} 
                  title ='Zermat'
                  className={classes.imgSizeLogo} />
                </Grid>
            
                <Grid item>
                    <Icon className={clsx(classes.icon, 'far fa-lightbulb')} style={{ fontSize: 22 }} onClick={onToggleDark}
                    title ='Theme'/>
                </Grid>

                <Grid item >
                  {this.state.AccesoPedido &&
                    <IconButton color="inherit"  title ='Pedido' component={Link} to='/Pedido'>  
                    {!muestraItems && <ShoppingCartOutlinedIcon />}
                    {muestraItems && <Badge badgeContent = {numItems} color="secondary">
                      <ShoppingCartOutlinedIcon />            
                    </Badge>}
                  </IconButton>
                  }
                </Grid>

                <Grid item title ='Salir'  >
                {/* <Button color="inherit" onClick={this.clickLogOut} > */}
                        <ExitToApp color="inherit" onClick={this.clickLogOut} title ='Salir'  />
                {/* </Button> */}
                </Grid>
             </Grid>

            </Toolbar>
          </AppBar>

          
          <Dialog open={this.state.dialogSesion} >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Aviso
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.
            </DialogContentText>
          </DialogContent>
          </Dialog>
          </div>

           )
    }
}

export default TopBar