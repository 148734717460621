
import React, { Component } from 'react'
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import jwt from 'jwt-decode';
import Controller from '../../controllers/Menu'
import SideList from '../SideList/index'
import CardLider from '../CardLider/index'



class SideBar extends Component {

    constructor(props){
        super(props)
        this.state = {
          data:{},
          lider :'',
          pais :''
      }
     }


  componentDidMount(){   

      const key = localStorage.getItem("Authorization")
      this.setState({keylocst:key})
      const jwtkey = jwt(key)
      this.setState({lider:jwtkey.user.id})
      this.setState({pais:jwtkey.user.idPais})

      setTimeout(()=>{
          const dataF = Controller.fetchDataInfoLider(this.state.lider,this.state.pais.toString())
          dataF.then(res=>{ 
              this.setState({data:res})                
          })
        }, 500)   


      
  }



  render() {
      const {classes,open,handleDrawerClose} =  this.props

      return (
          <Drawer  
          //variant="permanent" 
          classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
            onClick={this.handleDrawerClose}
          >

            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />  
            {open && <CardLider data = {this.state.data}/>}
            <SideList data = {this.state.data}/>

          </Drawer>
      )
  }
}

export default SideBar