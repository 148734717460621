import API from '../api/Edocta';

const Controller = {
    fetchDataEdocta: async(lider,pais) =>{
        return await API.getEdocta(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },fetchDataSaldo: async(lider,pais) =>{
        return await API.getSaldo(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }
}


     
export default Controller


