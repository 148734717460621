import React, {Component} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useStyles from '../../../styles/ui-styles'
import SideBar from '../../SideBar/index'
import TopBar from '../../TopBar/index'
import Grid from '@material-ui/core/Grid';

import Controller from '../../../controllers/Reportes';
import Divider from '@material-ui/core/Divider'
import ReporteCreditosLider from './creditosLider';

import jwt from 'jwt-decode';

class RptCreditosLider extends Component {
  constructor(props){
    super(props)
    this.state = {
        open:false,
        rows:[],
        porvencer:'0',
        vencido:'0',
        total:'0',
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)  
   }
   

    
 componentDidMount(){
  const key = localStorage.getItem("Authorization") 
  const jwtkey = jwt(key)
  Controller.fetchDataCreditosLider(jwtkey.user.id.toString(),jwtkey.user.idPais.toString())
  .then(res => {    
      if (res.data.success === true){        
      this.setState({rows:res.data.data.data})
    }
  })
  Controller.fetchDataCreditosLiderTotal(jwtkey.user.id.toString(),jwtkey.user.idPais.toString())
  .then(res => {  

    console.log(res.data.data.data)

    if (res.data.data.data.length > 1){
        res.data.data.data.map(data => {
            if (data.Comentarios === 'VENCIDO'){
                this.setState({vencido:data.Saldo})
            }else if (data.Comentarios === 'POR VENCER'){
                this.setState({porvencer:data.Saldo})
            }else{
                this.setState({total:data.Saldo})
            }
        })
    }

    //   this.setState({porvencer:res.data.data.data[0].Saldo,
    //     vencido:res.data.data.data[1].Saldo,
    //     total:res.data.data.data[2].Saldo})
  })

}




  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }
  

  
  render(){
      const {classes,onToggleDark} = this.props
      return (     
        <div className={classes.root}>
          <CssBaseline />
          <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark}/>
          <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          
             
          <main className={classes.content}>         
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant='h4' component="p">
                Estado de Cuenta de Créditos por Líder
                </Typography> 
                <div className={classes.appBarSpacer} />
                <Grid container spacing={3}>
              </Grid>  

    
              <Grid xs = {10}  > 
                <Grid item container >
                    <Grid item  xs = {7}   > 
                        <Typography variant='h6' component="p">Total Vencido: </Typography> 
                        <Divider  />
                    </Grid>
                    <Grid item  xs = {2}   > 
                        <Typography variant='h6' component="p">{this.state.vencido}
                        </Typography> 
                        <Divider  />
                    </Grid>
                </Grid>
                <Grid item container >
                    <Grid item  xs = {7}   > 
                        <Typography variant='h6' component="p">Total por Vencer: </Typography> 
                        <Divider  />
                    </Grid>
                    <Grid item  xs = {2}  > 
                        <Typography variant='h6' component="p">{this.state.porvencer}
                        </Typography> 
                        <Divider  />
                    </Grid>
                </Grid>
                <Grid item container >
                <Grid item  xs = {7}  > 
                        <Typography variant='h6' component="p">Total Saldos: </Typography> 
                        <Divider  />
                    </Grid>
                    <Grid item xs={2} >
                        <Typography variant='h6' component="p">{this.state.total}
                        </Typography> 
                        <Divider  />
                    </Grid>
                </Grid>
             </Grid>
         
             <CssBaseline />

             <ReporteCreditosLider rows={this.state.rows}/>

        
           
            </Container>  
            </main>

    
        </div>
     
     
     
     )

    }
}

export default withStyles(useStyles)(RptCreditosLider)
