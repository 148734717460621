import React, { Component } from 'react';

import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteForever from '@material-ui/icons/DeleteForever';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Loading from '../../Loading/index'

import Button from '@material-ui/core/Button'

import controller from '../../../controllers/Pedidos';
import controllerProd from '../../../controllers/Productos';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Producto extends Component{
    constructor(props){
        super(props)
        this.state = {       
            qtyPed:0,
            Codart:'',
            TipoLinea:'',
            IdPromo:0,
            IdPromoMXD:0,
            CsetVariable:0,        
      
            lstProd :[],
            numItems:'',
            muestraItems : false,
            Importe:0,

            opendialogInv:false,          
            load:false,
            
            mensajeAdd:'',
            opendialogProdAdd:false


        }

        this.clickHandlerDel = this.clickHandlerDel.bind(this);  
        this.clickHandlerAdd = this.clickHandlerAdd.bind(this); 
    } 
    componentDidMount(){   
        this.setState({qtyPed:this.props.QtyPed,
            Codart:this.props.Codigo,
            TipoLinea:this.props.TipoLinea,
            IdPromo:this.props.IdPromo,
            IdPromoMXD:this.props.IdPromoMXD,
            CsetVariable:this.props.CsetVariable,                     
        })  


    }
    clickHandlerDel = () => {   
        this.props.clickConfirmDelete(this.props.Codigo,this.props.TipoLinea,this.props.IdPromo,this.props.IdPromoMXD,this.props.CsetVariable)  
    }
    clickHandlerAdd = () => { 
        const qtyAdd = 1
        const pedido = localStorage.getItem("Pedido")  
        this.setState({load:true})
        setTimeout(()=>{  
            /*valida inventario*/
            controllerProd.fetchgetConsultaInventario(this.props.Pais,this.props.Codigo,qtyAdd,localStorage.getItem("Pedido") ,this.props.IdCdz)
            .then(resinv=>{ 
                if(resinv === '1'){                
                    /*Agrega Producto a pedido*/ 
                controller.fetchpostInsPedidoDet(pedido,this.props.Codigo,qtyAdd,this.props.Pais.toString())
                .then(resPed=>{   

                       /*muestra mensaje en caso de haber algun error*/
                       if(resPed.Agregado === 0){
                        this.setState({opendialogProdAdd:true,mensajeAdd:resPed.Mensaje.toString()})
                        setTimeout(()=>{         
                            this.setState({opendialogProdAdd:false,mensajeAdd:''})
                        }, 1500) 
                        }
                        /*obtener detalle de pedido*/          
                        controller.fetchPedido(pedido,this.props.Pais.toString())
                        .then(resPed=>{    
                        
                                const filterCodigo = resPed.filter(linea => linea.Codart.includes(this.props.Codigo))                    
                                this.setState({qtyPed:filterCodigo[0].QtyPed})
                                this.setState({lstProd:resPed})  
                                this.props.addActPedido(resPed,this.state.Codart)
                                this.setState({load:false})
                        })                   
                    }) 

                }else if(resinv === '0') {
                    this.props.handleOpenDialogInv()
                    this.setState({load:false})
                }else{
                    this.setState({opendialogProdAdd:true,mensajeAdd:'Por favor intenta nuevamente'})
                    setTimeout(()=>{         
                      this.setState({opendialogProdAdd:false,mensajeAdd:''})
                    }, 1000)
                }
            })
            .catch(()=>{
                this.setState({opendialogProdAdd:true,mensajeAdd:'Por favor intenta nuevamente'})
                setTimeout(()=>{         
                    this.setState({opendialogProdAdd:false,mensajeAdd:''})
                }, 1000)
            })
        }, 500)  
    }
    render(){
        const {classes} = this.props
        return (
           
        <div>                
            {this.state.load && <Loading/>}
            {!this.state.load && 
                
                <Card >
             
                <CardContent> 
                    <Typography   variant="body1"  align="center"> 
                            {this.props.Codigo}
                    </Typography>

                    <Grid item xs={12} align ='center'>            
                        <img src=  {this.props.Imagen} 
                              onError={(e)=>{e.target.src= 'https://zermatinternal.com:3030/ImagenesPaises/img/Catalogo/NoDisponible.png'}}
                        title=  {this.props.Descripcion}
                        alt=  {this.props.Descripcion}
                         width= "150px"   height= "150px" />        
                    </Grid>

             
                    <Grid item xs={12}  container>   
                            <Typography  noWrap variant="body1"  align="center"> 
                                    {this.props.Descripcion}
                                </Typography> 
                    </Grid>
              
                    {(this.props.TipoLinea !== 'G' && this.props.TipoLinea !== 'P') && 
                     <Paper className={classes.paperCard}>   
                        <Grid container spacing={3}>                     
                            <Grid item xs={12}  container>   
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >Catalogo: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}  {this.props.PreUniIICat}	 </Typography> 
                                    
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >Descuento: </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >{this.props.Descuento} %  </Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                <Typography variant="subtitle1">Total: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda} {this.props.MontoLinII}  </Typography> 
                                </Grid>
                            </Grid>  
                        </Grid>
                    </Paper> 
                    }
                    {this.props.TipoLinea === 'G' && 
                    <Paper className={classes.paperCardPromo}>   
                        <Grid container spacing={3}>                     
                            <Grid item xs={12}  container>   
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >Catalogo: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}  {this.props.PreUniIICat}	 </Typography> 
                                    
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >Descuento: </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >{this.props.Descuento} %  </Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                <Typography variant="subtitle1">Total: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda} {this.props.MontoLinII}  </Typography> 
                                </Grid>
                            </Grid>  
                        </Grid>
                    </Paper>     
                     }

                    {this.props.TipoLinea === 'P' && 
                    <Paper className={classes.paperCardPaq}>   
                        <Grid container spacing={3}>                     
                            <Grid item xs={12}  container>   
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >Catalogo: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda}  {this.props.PreUniIICat}	 </Typography> 
                                    
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >Descuento: </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="subtitle1" >{this.props.Descuento} %  </Typography> 
                                </Grid>
                            </Grid>  
                            <Grid item xs={12}  container>   
                                <Grid item xs={6} sm={6}>
                                <Typography variant="subtitle1">Total: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" >{this.props.Moneda} {this.props.MontoLinII}  </Typography> 
                                </Grid>
                            </Grid>  
                        </Grid>
                    </Paper>     
                     }

                    <Grid item xs={12}  container  alignItems="center" justify="center" > 
                    <Grid item  xs={3}  > 
                        <TextField
                            id="outlined-margin-dense"
                         
                            className={classes.iconPed}
                            // value={this.state.qtyPed}
                            value={this.props.QtyPed}
                            margin="dense"
                            variant="outlined"
                            align="center"
                            label="Qty"          
                            readOnly >
                        </TextField> 
                    </Grid>
                    <Grid item xs={3}> 
                        <Button   color="primary"  title="Agregar">     
                            <AddShoppingCartIcon color="inherit" 
                             className={classes.iconPed}
                             style={{ fontSize: 35, color:'#4caf50' }}
                             onClick={this.clickHandlerAdd}
                            /> 
                        </Button>  
                    </Grid>
                    <Grid item  xs={3}  >        
                        <Button   color="secondary" title="Eliminar">   
                        <DeleteForever color="inherit" 
                             className={classes.iconPed}
                             style={{ fontSize: 35, color:'#c62828' }}
                            
                             onClick={this.clickHandlerDel} /> 
                        
                        </Button>    

                    </Grid> 
                </Grid> 
                </CardContent>
                </Card>  
            }

            <Dialog open={this.state.opendialogProdAdd} >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Aviso
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            {this.state.mensajeAdd} 
                            </DialogContentText>
                        </DialogContent>                        
                    </Dialog>

            </div>
               

       
        )
    }
}

export default (withStyles(useStyles)(Producto))
