import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import useStyles from '../../styles/ui-styles';
import Container from '@material-ui/core/Container';

import Direcciones from '../../config/general'
import jwt from 'jwt-decode';

class Footer extends Component {



    render() {
        const {classes} =  this.props

        return (

          <React.Fragment>
          {/* <CssBaseline /> */}
         
          <footer className={classes.footer}>
            <Container maxWidth="lg" >
 
                {/* <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href={this.state.web}target="_blank">
                  Zermat Internacional
                </Link>{' '}
                {new Date().getFullYear()}      
              </Typography> */}
              <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}   {'Zermat Internacional'}   
                {' '+new Date().getFullYear()}      
              </Typography> 

    
            </Container>
          </footer>
          
        </React.Fragment>

           )
    }
}

export default withStyles(useStyles)(Footer);