import React, {Component} from 'react';
import { Document, Page } from 'react-pdf';
import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../styles/ui-styles';


import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';


class Pdf extends Component {

  constructor(props){
    super(props)
    this.state = {
      open:false,
      numPages: null,
      pageNumber: 1,
      filepdf :'', 
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this); 
  }
  componentDidMount(){
    this.setState({filepdf : this.props.Pdf})
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  goToPrevPage = () =>{
    if(this.state.pageNumber !== 1 ){
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    }
  }    
  goToNextPage = () =>{
    if(this.state.pageNumber !== this.state.numPages){
      this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
    }
  }
   
  render(){

      const {classes} = this.props;
      const { pageNumber, numPages } = this.state;
      
      
      return (     
        <div >     
          <Grid container >   
              <Grid item xs={12} container  justify="center">
                  <Document file = {this.state.filepdf} onLoadSuccess={this.onDocumentLoadSuccess} >

                      <Page pageNumber={pageNumber} />
                      <p>Page {pageNumber} of {numPages}</p>



                  </Document> 
              </Grid>
              <Grid item xs={12} container  justify="center">
                <Button color="primary" title="Anterior" variant="outlined" size='small'  className = {classes.buttonpdf}>          
                    <SkipPreviousIcon  style={{ fontSize: 38, }} onClick={this.goToPrevPage} />
                </Button>
                <Button color="primary" title="Siguiente" variant="outlined" size='small' className ={classes.buttonpdf}>   
                     <SkipNextIcon  style={{ fontSize: 38, }} onClick={this.goToNextPage}/>
                </Button>  
             </Grid>
          </Grid>         
        </div>
      )
    }
}

export default withStyles(useStyles) (Pdf)