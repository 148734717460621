import React from 'react';

import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
// import LocalLibrary from '@material-ui/icons/LocalLibrary';
import LocalLibrary from '@material-ui/icons/LocalLibraryOutlined';
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
//import StorefrontIcon from '@material-ui/icons/Storefront';
//import StorefrontIcon from '@material-ui/icons/AccessAlarm';
import StorefrontIcon from '@material-ui/icons/StoreMallDirectory';

import DescriptionIcon from '@material-ui/icons/Description';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// import WhatsAppIcon from '@material-ui/icons/WhatsApp';



const ICONS = {
    Dashboard: <DashboardIcon  style={{ color:'#3cadbd' }}/>,
    Barchart:  <BarChartIcon  style={{ color:'#ff5722' }}/>,

    Assignment: <AssignmentIcon   style={{ color:'#0d47a1' }}/>, 
    LocalLibrary: <LocalLibrary  style={{ color:'#0d47a1' }} />, 

    monetization: <MonetizationOnIcon  style={{ color:'#00c853' }}/>,
    storefront:<StorefrontIcon   style={{ color:'#00c853' }}/>,

    DescriptionIcon:<DescriptionIcon   style={{ color:'#ff5722' }}/>,
    LocalShippingIcon: <LocalShippingIcon   style={{ color:'#0d47a1' }}/>, 

    ShoppingCartOutlinedIcon : <ShoppingCartOutlinedIcon   style={{ color:'red' }}/>, 

    HelpOutlineIcon : <HelpOutlineIcon   style={{ color:'#e91e63' }}/>, 

    InfoOutlinedIcon : <InfoOutlinedIcon   style={{ color:'#7e57c2' }}/>, 
    

    
    
    
    
};


export default ICONS;