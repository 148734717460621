import axios from 'axios'
import url from './url'

const Api = {
    getGanancias: async (lider, mes, anio, pais) =>{
        var data = {
            'idLider': lider,
            'mes': mes, 
            'anio': anio,
            'pais': pais
        }
        return await axios.post(url+'/multinivel/arbol', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getGananciasxGen: async (lider, mes, anio, pais) =>{
        var data = {
            'idLider': lider,
            'mes': mes, 
            'anio': anio,
            'pais': pais
        }
        return await axios.post(url+'/multinivel/arbolxGen', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getCierreCab: async (lider, mes, anio, pais) =>{
        var data = {
            'idLider': lider,
            'mes' : mes,
            'anio': anio,
            'pais': pais
        }
        return await axios.post(url+'/multinivel/cierrecab', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getCierreDet: async (lider, mes, anio, pais, generacion,movimientos) =>{
        var data = {
            'idLider': lider,
            'mes' : mes,
            'anio': anio,
            'pais': pais, 
            'generacion': generacion,
            'movimientos': movimientos,
        }
        return await axios.post(url+'/multinivel/cierredet', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getCierreTot: async (lider, mes, anio, pais) =>{
        var data = {
            'idLider': lider,
            'mes' : mes,
            'anio': anio,
            'pais': pais
        }
        return await axios.post(url+'/multinivel/cierretot', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getCierrexGen: async (lider, mes, anio, pais, generacion,movimientos) =>{
        var data = {
            'idLider': lider,
            'mes' : mes,
            'anio': anio,
            'pais': pais, 
            'generacion': generacion,
            'movimientos': movimientos,
        }
        return await axios.post(url+'/multinivel/cierrexgen', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
}
export default Api