
const Objeto =[
    {
        "id": 1,
        "Pedido": "1",   
        "Catalogo": "https://zermatinternal.com:3030/Ecuador/Catalogo/",
        "Estrategias": "https://zermatinternal.com:3030/Ecuador/Estrategias/", 
        "moneda" : "$",
        "Terminos": "https://zermatinternal.com:3030/Ecuador/Terminos",
        "Aviso": "https://zermatinternal.com:3030/Ecuador/Aviso",
        "Imagenes": "https://zermatinternal.com:3030/ImagenesPaises",
    
    },
    {
        "id": 2,
        "Pedido": "1",   
        "Catalogo": "https://zermatinternal.com:3030/Bolivia/Catalogo/",
        "Estrategias": "https://zermatinternal.com:3030/Bolivia/Estrategias/", 
        "moneda" : "$",
        "Terminos": "https://zermatinternal.com:3030/Bolivia/Terminos",
        "Aviso": "https://zermatinternal.com:3030/Bolivia/Aviso",
        "Imagenes": "https://zermatinternal.com:3030/ImagenesPaises",
    
    },
    {
        "id": 3,
        "Pedido": "1",   
        "Catalogo": "https://zermatinternal.com:3030/Peru/Catalogo/",
        "Estrategias": "https://zermatinternal.com:3030/Peru/Estrategias/", 
        "moneda" : "S",
        "Terminos": "https://zermatinternal.com:3030/Peru/Terminos",
        "Aviso": "https://zermatinternal.com:3030/Peru/Aviso",
        "Imagenes": "https://zermatinternal.com:3030/ImagenesPaises",
    
    }
  
]
    
    export default Objeto;