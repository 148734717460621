/**
 * @description Controlador del Login.
 * se pasan como parámetros el usuario, el password y el país de selección.
*/
import API from '../api/Login';

const Controller = {
    fetchDataLogin: async (usuario, password, pais, ipPub,platform,os,browser) =>{
         return await API.getAcceso(usuario, password, pais,ipPub,platform,os,browser)
        .then(resp => {
         
            return resp;
        })
        .catch(err =>{
            return err;
        })
    },
    fetchToken: async (usuario,pais) =>{       
        return await API.getToken(usuario,pais)
       .then(resp => {
           return resp;
       })
       .catch(err =>{
           return err;
       })
    },
    fetchValidaSesionLg: async (usuario,pais) =>{       
    return await API.getValidaSesionLg(usuario,pais)
    .then(resp => {
        return resp;
    })
    .catch(err =>{
        return err;
    })
    },
    fetchCierraSesionLg: async (usuario,pais) =>{       
        return await API.CierraSesionLg(usuario,pais)
        .then(resp => {
            return resp;
        })
        .catch(err =>{
            return err;
        })    
    },
    fetchValidaSesionTk: async (usuario,pais) =>{       
    return await API.getValidaSesionTk(usuario,pais)
    .then(resp => {
        return resp;
    })
    .catch(err =>{
        return err;
    })
    
    },
    fetchCierraSesionTk: async (usuario,pais) =>{       
    return await API.CierraSesionTk(usuario,pais)
    .then(resp => {
        return resp;
    })
    .catch(err =>{
        return err;
    })    
    },
}

export default Controller