import API from '../api/Dashboard';

const Controller = {
    fetchDataCompraPersonal: async(lider,pais) =>{
   
        return await API.getCompraPersonal(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.compraPer[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataGrupoDirecto: async(lider,pais) =>{      
        return await API.getGrupoDirecto(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.grupoDir[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataSaldoLider: async(lider,pais) =>{
      
        return await API.getSaldoLider(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.saldoLider[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataEmpActivas: async(lider,pais) =>{
   
        return await API.getEmpActivas(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.empreAct[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataNumLideres: async(lider,pais) =>{
   
        return await API.getNumLideres(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {       
                return  resp.data.numLideres[0]
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataVtaTotales: async(lider,pais) =>{
        const array = []  
        return await API.getVtasTotales(lider,pais)     
        .then(resp => {            
            if (resp.data.success) {        
                let Dat = {}
                Dat = Data.createDataBarChart(resp.data.vtasTot[0].ENC3,resp.data.vtasTot[0].VTAMES3)
                array.push(Dat)
                Dat = Data.createDataBarChart(resp.data.vtasTot[0].ENC2,resp.data.vtasTot[0].VTAMES2)
                array.push(Dat)
                Dat = Data.createDataBarChart(resp.data.vtasTot[0].ENC1,resp.data.vtasTot[0].VTAMES1)
                array.push(Dat)
                return  array
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataVtaComisionables: async(lider,pais) =>{
        const array = []  
        return await API.getVtasComisionables(lider,pais)
        .then(resp => {               
            if (resp.data.success) {        
                let Dat = {}
                Dat = Data.createDataBarChart(resp.data.vtasCom[0].ENC3,resp.data.vtasCom[0].VTAMESCOM3)
                array.push(Dat)
                Dat = Data.createDataBarChart(resp.data.vtasCom[0].ENC2,resp.data.vtasCom[0].VTAMESCOM2)
                array.push(Dat)
                Dat = Data.createDataBarChart(resp.data.vtasCom[0].ENC1,resp.data.vtasCom[0].VTAMESCOM1)
                array.push(Dat)
                return  array
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }
}

const Data = {
    createDataBarChart:(name, uv) =>{
        return { name, uv };
      }
}
     
export default Controller


