import React, { Component } from "react";
import TablaEmprendedor from '../../Table/index';

class genanciaslider extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'Generacion', label: 'Generación', minWidth: 100 },
                    { id: 'idPatrocinador', label: 'Patrocinador', minWidth: 120 },
                    { id: 'NombrePatrocinador', label: 'Nombre Patrocinador', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'idCli', label: 'ID Cliente', minWidth: 120 },
                    { id: 'Nombrecli', label: 'Nombre', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'VentaCli', label: 'Ventas', minWidth: 120, format: value => value.toFixed(2),},
                    { id: 'Porcentaje', label: 'Porcentaje', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'ComiClie', label: 'Comisión', minWidth: 120, format: value => value.toFixed(2), },

                    { id: 'CategoriaCli', label: 'Categoria', minWidth: 120 },
                    { id: 'TelCli', label: 'Telefono', minWidth: 120 },
                    { id: 'FecIngCli', label: 'Fecha Ingreso', minWidth: 120 },

                  ]}
              />
          );
    }
  }

  export default genanciaslider