import React, {Component} from 'react';

import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Copyright from '../Copyright/index'
import useStyles from '../../styles/ui-styles'
import BarChart from '../BarChart/index';
import Loading from '../Loading/index';

import SideBar from '../SideBar/index'
import TopBar from '../TopBar/index'
import ZmtSaldo from '../Saldo/index'
import PieChart from '../ChartPie/index'

import Controller from '../../controllers/Dashboard'
import jwt from 'jwt-decode';
import controllerPed from '../../controllers/Pedidos'

import Score from '../Score/index'
import CircularProBar from '../CircularProgBar'

class Dashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      open:false,
      loadSaldo:false,
      dataSaldo:[],
      loadCompraPersonal:false,
      dataCompraPersonal:[],
      loadGrupoDirecto:false,
      dataGrupoDirecto:[],
      loadVentasTotales:false,
      dataVentasTotales:[],
      loadVentasComisionable:false,
      dataVentasComisionables:[],
      loadEmpActivas:false,
      dataEmpActivas:[],
      loadNumLideres:false,
      dataNumLideres:[],
      lider :'',
      pais :'',
      loadInit :false,
      numItems:'',
      muestraItems : false,
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
   
  }
  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

 componentDidMount(){
    const key = localStorage.getItem("Authorization") 
    const jwtkey = jwt(key)
  
    this.setState({lider:jwtkey.user.id})
    this.setState({pais:jwtkey.user.idPais})

    
 
    setTimeout(()=>{
      const data = Controller.fetchDataVtaTotales(this.state.lider,this.state.pais.toString())
      data.then(res=>{   
        this.setState({dataVentasTotales:res})
        this.setState({loadVentasTotales:true})
      }).finally(()=>{
          const data2 = Controller.fetchDataVtaComisionables(this.state.lider,this.state.pais.toString())
          data2.then(res=>{   
            this.setState({dataVentasComisionables:res})
            this.setState({loadVentasComisionable:true})
          }).finally(()=>{
            const data3 = Controller.fetchDataSaldoLider(this.state.lider,this.state.pais.toString())
            data3.then(res=>{   
              this.setState({dataSaldo:res})
              this.setState({loadSaldo:true})
            }).finally(()=>{
              const data4 = Controller.fetchDataCompraPersonal(this.state.lider,this.state.pais.toString())
              data4.then(res=>{   
                this.setState({dataCompraPersonal:res})
                this.setState({loadCompraPersonal:true})
              }).finally(()=>{
                const data5 = Controller.fetchDataGrupoDirecto(this.state.lider,this.state.pais.toString())
                data5.then(res=>{   
                  this.setState({dataGrupoDirecto:res})
                  this.setState({loadGrupoDirecto:true})
                }).finally(()=>{
                  const data6 = Controller.fetchDataEmpActivas(this.state.lider,this.state.pais.toString())
                  data6.then(res=>{   
                    this.setState({dataEmpActivas:res})
                    this.setState({loadEmpActivas:true})
                  }).finally(()=>{
                    const data7 = Controller.fetchDataNumLideres(this.state.lider,this.state.pais.toString())
                    data7.then(res=>{   
                      this.setState({dataNumLideres:res})
                      this.setState({loadNumLideres:true})
                    })
                  })
                })

              })
            })            
          })
      })  
    }, 1200)
    


    /*obtener numero de lineas y monto de pedido*/
    const pedido = localStorage.getItem("Pedido")  

    if(pedido){
      controllerPed.fetchPedidoNumItems(pedido,jwtkey.user.idPais.toString())
        .then(res=>{   
            this.setState({numItems:res.NumItems,
                muestraItems:true
            })         
        }).finally(()=>{
             controllerPed.fetchgetValidaEstatusPedido(pedido,jwtkey.user.idPais.toString())
            .then(res=>{                           
                   if(res.data.data.Estatus[0].Cliente.toString() !== this.state.lider){
                      window.localStorage.removeItem('Pedido') 
                      this.setState({numItems:'',
                        muestraItems:false
                    })  
                   }   
            })
        }) 
    }

  }

    render(){

      const {classes, onToggleDark} = this.props
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

      return (
     
        <div className={classes.root}>
          <CssBaseline />
    
          <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark} numItems={this.state.numItems}muestraItems={this.state.muestraItems}/>
          <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
          
          <main className={classes.content}>
         
            <div className={classes.appBarSpacer} />              
            <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>

                {/* Compra Personal */}
             
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                  {!this.state.loadCompraPersonal && <Loading/>}    
                  {this.state.loadCompraPersonal &&<Typography component="p" variant="h5"align="center">Compras Propias</Typography>}
                  {/* {this.state.loadCompraPersonal &&<Score  data={this.state.dataCompraPersonal} />}  */}
                  {this.state.loadCompraPersonal &&<CircularProBar  data={this.state.dataCompraPersonal} />} 
                  {this.state.loadCompraPersonal &&<Typography component="p" variant="subtitle1"align="center">Monto : {this.state.dataCompraPersonal.Compra} /     Meta :  {this.state.dataCompraPersonal.Meta} </Typography>} 
                  </Paper>
                </Grid>



                {/* Compra Grupo Directo */}
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                  {!this.state.loadGrupoDirecto && <Loading/>}    
                  {this.state.loadGrupoDirecto &&<Typography component="p" variant="h5"align="center">Grupo Directo</Typography>}
                  {/* {this.state.loadGrupoDirecto &&<Score  data={this.state.dataGrupoDirecto} />}  */}
                  {this.state.loadGrupoDirecto &&<CircularProBar  data={this.state.dataGrupoDirecto} />} 
                  {this.state.loadGrupoDirecto &&<Typography component="p" variant="subtitle1"align="center">Monto : {this.state.dataGrupoDirecto.CompraGD} /    Meta :  {this.state.dataGrupoDirecto.Meta} </Typography>} 
                  </Paper>
                </Grid>

                {/* Emprendedoras Activas */}
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                  {!this.state.loadEmpActivas && <Loading/>}    
                  {this.state.loadEmpActivas &&<Typography component="p" variant="h5"align="center">Emprendedoras Activas</Typography>}
                  {/* {this.state.loadEmpActivas &&<Score  data={this.state.dataEmpActivas} />}  */}
                  {this.state.loadEmpActivas &&<CircularProBar  data={this.state.dataEmpActivas} />} 
                  {this.state.loadEmpActivas &&<Typography component="p" variant="subtitle1"align="center">Activas : {this.state.dataEmpActivas.EmpAct} /    Meta :  {this.state.dataEmpActivas.Meta} </Typography>} 
                  </Paper>
                </Grid>

                {/* Lideres */}
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                  {!this.state.loadNumLideres && <Loading/>}    
                  {this.state.loadNumLideres &&<Typography component="p" variant="h5"align="center">Líderes</Typography>}
                  {/* {this.state.loadNumLideres &&<Score  data={this.state.dataNumLideres} />}  */}
                  {this.state.loadNumLideres &&<CircularProBar  data={this.state.dataNumLideres} />} 
                  {this.state.loadNumLideres &&<Typography component="p" variant="subtitle1"align="center">Líderes : {this.state.dataNumLideres.Lideres} /    Meta :  {this.state.dataNumLideres.Meta} </Typography>} 
                  </Paper>
                </Grid>

                {/* Saldo */}
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                    {!this.state.loadSaldo && <Loading/>}                  
                    {this.state.loadSaldo && <ZmtSaldo data={this.state.dataSaldo}/>}          
                  </Paper>
                </Grid>

                {/* Ventas Totales */}
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                    {!this.state.loadVentasTotales && <Loading/>}
                    {this.state.loadVentasTotales &&<Typography component="p" variant="h5"align="center">Ventas Totales</Typography>}
                    {this.state.loadVentasTotales && <BarChart data={this.state.dataVentasTotales} title = 'Ventas Totales'/>}          
                  </Paper>
                </Grid>

                 {/* Ventas Comisionables */}
                 <Grid item xs={12} md={6} lg={4}>
                  <Paper className={fixedHeightPaper}>
                    {!this.state.loadVentasComisionable && <Loading/>}
                    {this.state.loadVentasComisionable &&<Typography component="p" variant="h5"align="center">Ventas Comisionables</Typography>}
                    {this.state.loadVentasComisionable && <BarChart data={this.state.dataVentasComisionables} title = 'Ventas Totales'/>}          
                  </Paper>
                </Grid>

        
              </Grid>  
              
                 
              <div className={classes.appBarSpacer} />              
              <div className={classes.appBarSpacer} />   
            </Container>   
          </main>
          <Copyright />
    

        </div>
      )

    }
}

export default withStyles(useStyles) (Dashboard)