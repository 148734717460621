import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ZmtSideBar from '../../SideBar/index'
import ZmtTopBar from '../../TopBar/index'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';

import ReporteCierre from './cierrelider';
import ReporteCierrexGen from './cierreliderxgen';
import Controller from '../../../controllers/Multinivel';

import SelectMes from '../../SelectMes/index';
import lstMeses from '../../SelectMes/MesList';
import SelectAnio from '../../SelectAnio/index';


import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';
import LoadCir from '../../Loading';

class RptCierreLider extends Component{

  constructor(props){
    super(props)
    this.state = {
      open:false, 
      mes: '',
      anio: '',
      idLider :0,
      pais :'', 
      rows :[],
      meses: lstMeses, 
      movimientos: 1,
           
      gen1: [], 
      resp1: false,
      open1: false,
      gen2: [], 
      resp2: false,
      open2: false,
      gen3: [], 
      resp3: false,
      open3: false,
      gen4: [], 
      resp4: false,
      open4: false, 
      gen5: [], 
      resp5: false,
      open5: false, 
      gen6: [], 
      resp6: false,
      open6: false, 
      gen7: [], 
      resp7: false,
      open7: false, 
      response: false,
      load: false,

      gen1xgen: [], 
      gen2xgen: [], 
      gen3xgen: [], 
      gen4xgen: [], 
      gen5xgen: [], 
      gen6xgen: [], 
      gen7xgen: [], 


    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleChangeMes = this.handleChangeMes.bind(this);
    this.handleChangeAnio = this.handleChangeAnio.bind(this);
    this.handleChangeMov = this.handleChangeMov.bind(this);

    
    this.getGanancias = this.getGanancias.bind(this);
    this.getCabecera = this.getCabecera.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){
    const key = localStorage.getItem("Authorization")
    this.setState({keylocst:key})
    const jwtkey = jwt(key)
    this.setState({idLider:jwtkey.user.id});
    this.setState({pais:jwtkey.user.idPais});
  }

  handleClick(e) {
    const gen = e.currentTarget.id;
    let staOpen 

    switch (gen) {
      case "1":
        this.state.open1 === true ? staOpen= false : staOpen =true;
        this.setState({open1:staOpen});   
        break;
      case "2":
        if(this.state.open2 === false){
          this.setState({load:true});
          const data2 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
          data2.then(res=>{
              if(res.data.data.success === true){
                this.setState({gen2:res.data.data.registros, resp2:true,open2:true});

                Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
                .then(res=>{ 
                   if(res.data.data.success === true){
                      this.setState({gen2xgen:res.data.data.registros})
                   }
                })

              }else{this.setState({gen2:[], resp2:false});}
          }).catch(e=>{
            this.setState({open2:false});
          }).then(() => {this.setState({load:false})})
        }else{this.setState({open2:false})}
        break;
      case "3":
        if(this.state.open3 === false){
          this.setState({load:true});
          const data3 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
          data3.then(res=>{
              if(res.data.data.success === true){
              this.setState({gen3:res.data.data.registros, resp3:true, open3:true});

              Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
              .then(res=>{ 
                 if(res.data.data.success === true){
                    this.setState({gen3xgen:res.data.data.registros})
                 }
              })

            }else{this.setState({gen3:[], resp3:false});}
          }).catch(e=>{
            this.setState({open3:false});   
          })
          .then(() => {this.setState({load:false})})
        }else{this.setState({open3:false})}
        break;
      case "4":
        if(this.state.open4 === false){
          this.setState({load:true});
          const data4 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
          data4.then(res=>{
              if(res.data.data.success === true){
              this.setState({gen4:res.data.data.registros, resp4:true, open4:true});

              Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
              .then(res=>{ 
                 if(res.data.data.success === true){
                    this.setState({gen4xgen:res.data.data.registros})
                 }
              })

            }else{this.setState({gen4:[], resp4:false,open4:false});}
          }).catch(e=>{
            this.setState({open4:false});   
          }).then(() => {this.setState({load:false})})
        }else{this.setState({open4:false})}
        break;
      case "5":
        if(this.state.open5 === false){
          this.setState({load:true});
          const data5 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
          data5.then(res=>{
              if(res.data.data.success === true){
              this.setState({gen5:res.data.data.registros, resp5:true, open5:true});

              Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
              .then(res=>{ 
                 if(res.data.data.success === true){
                    this.setState({gen5xgen:res.data.data.registros})
                 }
              })

            }else{this.setState({gen5:[], resp5:false, open5:false});}
          }).catch(e=>{
            this.setState({open5:false});   
          }).then(() => {this.setState({load:false})})
        }else{this.setState({open5:false})}
        break;
      case "6":
        if(this.state.open6 === false){
          this.setState({load:true});
          const data6 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
          data6.then(res=>{
              if(res.data.data.success === true){
              this.setState({gen6:res.data.data.registros, resp6:true, open6:true});

              Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
              .then(res=>{ 
                 if(res.data.data.success === true){
                    this.setState({gen6xgen:res.data.data.registros})
                 }
              })

            }else{this.setState({gen6:[], resp6:false, open6:false});}
          }).catch(e=>{
            this.setState({open6:false});   
          }).then(() => {this.setState({load:false})})
        }else{this.setState({open6:false})}
        break;
      case "7":
          if(this.state.open7 === false){
            this.setState({load:true});
            const data7 = Controller.fetchDataCierreDet(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
            data7.then(res=>{
                if(res.data.data.success === true){
                this.setState({gen7:res.data.data.registros, resp7:true, open7:true});

                Controller.fetchDataCierrexGen(this.state.idLider, this.state.mes, this.state.anio, this.state.pais.toString(),gen,this.state.movimientos)
              .then(res=>{ 
                 if(res.data.data.success === true){
                    this.setState({gen7xgen:res.data.data.registros})
                 }
              })

              }else{this.setState({gen7:[], resp7:false, open7:false});}
            }).catch(e=>{
              this.setState({open7:false});   
            }).then(() => {this.setState({load:false})})
          }else{this.setState({open7:false})}
          break;
      default:
        break;
    }
  }

  handleDrawerOpen = () => {
    this.setState({open:true})
  }
  handleDrawerClose = () => {
    this.setState({open:false})
  }

  handleChangeMes(e){
    this.setState({mes: e.target.value})
  }

  handleChangeAnio(e){
    this.setState({anio: e.target.value});
  }

  handleChangeMov(e){
    this.setState({movimientos: e.target.value});
  }



  getGanancias(){
      const idLider = this.state.idLider;
      const pais = this.state.pais;
      const mes = this.state.mes;
      const anio = this.state.anio;
      const movimientos = this.state.movimientos;
      if(mes !== '' && anio !== ''){
        setTimeout(() => {
          const data = Controller.fetchDataCierreCab(idLider, mes, anio, pais.toString())
          data.then(res=>{               
            if(res.data.data.success === true){
              this.setState({rows:res.data.data.registros, response:true});
            }else{this.setState({rows:[], response:false});}
          }).finally(()=>{           
              const data1 = Controller.fetchDataCierreDet(idLider, mes, anio, pais.toString(),1,movimientos)
              data1.then(res=>{   
              if(res.data.data.success === true){
                this.setState({gen1:res.data.data.registros, resp1:true})}else{this.setState({gen1:[], resp1:false});}

                Controller.fetchDataCierrexGen(idLider, mes, anio, pais.toString(),1,movimientos)
                .then(res=>{ 
                   if(res.data.data.success === true){
                      this.setState({gen1xgen:res.data.data.registros})
                   }
                })

              }).catch(e=>{
             this.setState({rows:[], response:false});})
          }).catch(e=>{
             this.setState({rows:[], response:false});
          })  
     
        }, 1200);
      }
  }

 

  getCabecera(classes){
    if(this.state.response === true && this.state.rows.length >0){
      return(<Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {'LIDER: ' + this.state.rows[0].IDCLIEPROV + ' - ' + this.state.rows[0].NOMBRE}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {'CATEGORÍA: ' + this.state.rows[0].DESCRIPCION}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {'PERIODO: ' + this.state.rows[0].PERIODO}
          </Typography>
          <Typography variant="body2" component="p">{'FECHA INGRESO: ' + this.state.rows[0].FECHA_INGRESO}</Typography>
          <Typography variant="body2" component="p">{'MONTO CATALOGO: ' + this.state.rows[0].MONTOCAT}</Typography>
          <Typography variant="body2" component="p">{'FACTURADO: ' + this.state.rows[0].FACTURADO}</Typography>
          <Typography variant="body2" component="p">{'COMISIONABLE C/IVA: ' + this.state.rows[0].COMISIONABLE}</Typography>
          <Typography variant="body2" component="p">{'COMISIONABLE S/IVA: ' + this.state.rows[0].S_IVA}</Typography>
          
          <Typography variant="body2" component="h6" color="textSecondary" align="right">{'FECHA DE ÚLTIMA ACTUALIZACIÓN: ' + this.state.rows[0].ULTACT}</Typography>
        </CardContent>
      </Card>)
    }
  }

    render(){
        const {classes} = this.props
        return (
              <div className={classes.root}>
              <CssBaseline />
              <ZmtTopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen}/>
              <ZmtSideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                  <Typography variant='h4' component="p">
                  Cierre de Líderes
                  </Typography> 

                    <div className={classes.appBarSpacer} />
                    <Grid container spacing={3}>
                    <Grid item>
                      <InputLabel htmlFor="age-native-simple">Mes:</InputLabel>
                      <Select native required value={this.state.mes} onChange={this.handleChangeMes} inputProps={{name: 'Mes',id: 'age-native-simple',}}>
                        <SelectMes meses ={this.state.meses} />
                      </Select>
                    </Grid>
                    <Grid item><InputLabel htmlFor="age-native-simple">Año:</InputLabel>
                      <Select native required value={this.state.anio} onChange={this.handleChangeAnio} inputProps={{name: 'Año',id: 'age-native-simple',}}>
                        <SelectAnio/>
                      </Select>
                      </Grid>

                      <Grid item><InputLabel htmlFor="age-native-simple">Movimientos:</InputLabel>
                      <Select native required value={this.state.movimientos} onChange={this.handleChangeMov} 
                      inputProps={{name: 'Movimientos',id: 'age-native-simple',}}>

                        <option key={1} value={1}>{'Con Movimientos'}</option>
                        <option key={0} value={0}>{'Sin Movimientos'}</option>
                      
                      </Select>
                      </Grid>

                   <Grid item>
                     

       


                    <Button variant="outlined" size = 'small' color="primary"  className={classes.buttonpdf} onClick={this.getGanancias}>Ver</Button>
                      </Grid>
                      <div className={classes.appBarSpacer} />
                    </Grid>
                    {this.state.load && <LoadCir/>}
                    <div className={classes.appBarSpacer} />
                    {this.getCabecera(classes)}
                    {this.state.resp1 && <List component="nav" aria-labelledby="nested-list-subheader">
                      <ListItem button onClick={this.handleClick} id="1">
                          <ListItemText primary="1° Generación" />
                          {this.state.open1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open1} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            <ReporteCierre rows={this.state.gen1}/>
                          </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            <ReporteCierrexGen rows={this.state.gen1xgen}/>
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="2">
                          <ListItemText primary="2° Generación" />
                          {this.state.open2 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp2 && <ReporteCierre rows={this.state.gen2}/>}
                            </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            {this.state.resp2 &&  <ReporteCierrexGen rows={this.state.gen2xgen}/>}                           
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="3">
                          <ListItemText primary="3° Generación" />
                          {this.state.open3 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open3} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp3 && <ReporteCierre rows={this.state.gen3}/>}
                          </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            {this.state.resp3 &&  <ReporteCierrexGen rows={this.state.gen3xgen}/>}    
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="4">
                          <ListItemText primary="4° Generación" />
                          {this.state.open4 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open4} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp4 && <ReporteCierre rows={this.state.gen4}/>}
                            </ListItem>
                            <br/>
                            <ListItem button className={classes.nested}>
                            {this.state.resp4 &&  <ReporteCierrexGen rows={this.state.gen4xgen}/>}    
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="5">
                          <ListItemText primary="5° Generación" />
                          {this.state.open5 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open5} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp5 && <ReporteCierre rows={this.state.gen5}/>}
                           </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            {this.state.resp5 &&  <ReporteCierrexGen rows={this.state.gen5xgen}/>}    
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="6">
                          <ListItemText primary="6° Generación" />
                          {this.state.open6 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open6} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp6 && <ReporteCierre rows={this.state.gen6}/>}
                           </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            {this.state.resp6 &&  <ReporteCierrexGen rows={this.state.gen6xgen}/>}    
                          </ListItem>
                          </List>
                      </Collapse>
                      <ListItem button onClick={this.handleClick} id="7">
                          <ListItemText primary="7° Generación" />
                          {this.state.open7 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.open7} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            {this.state.resp7 && <ReporteCierre rows={this.state.gen7}/>}
                            </ListItem>
                          <br/>
                          <ListItem button className={classes.nested}>
                            {this.state.resp7 &&  <ReporteCierrexGen rows={this.state.gen7xgen}/>}    
                          </ListItem>
                          </List>
                      </Collapse>
                      </List>
                    }
                    <div className={classes.appBarSpacer} />  
                    <div className={classes.appBarSpacer} />  
                </Container>             
              </main>
         
            </div>
          );
    }
}
export default (withStyles)(useStyles) (RptCierreLider);