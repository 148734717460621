
const useStyles = (theme) => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.type.dark,
      },
    },
    'paper': {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    'avatar': {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    'form': {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    'submit': {
      //margin: theme.spacing(3, 0, 2),
      margin: theme.spacing(1),
    },
    'error': {
      backgroundColor: theme.palette.error.dark,
    }, 'icon': {
      fontSize: 20,
    },
    'iconVariant': {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    'message': {
      display: 'flex',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(1),
      bordercolor: theme.palette.secondary
  },

  });
  

  export default useStyles;