import React, { Component } from 'react'
import jwt from 'jwt-decode'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import SideBar from '../../SideBar/index'
import TopBar from '../../TopBar/index'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import useStyles from '../../../styles/ui-styles'


import Bloqueo from '../../BloqueoPage/index'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import controller from '../../../controllers/Pedidos'

import Producto from '../CardProductoPed/index'
import Moneda from '../../../config/general'

import Box from '@material-ui/core/Box';
import DatosLider from '../../DatosLider/index'

import StorefrontIcon from '@material-ui/icons/StoreMallDirectory';

import Snackbar from '@material-ui/core/Snackbar';

import { Link } from 'react-router-dom';

import Imagenes from '../../../config/general'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Pedido extends Component{
  constructor(props){
    super(props)
    this.state = {
      open:false, 
      pais :'', 
      idCdz:0,
      lstProd :[],
      idPedido:0 ,
      numItems:'',
      muestraItems : false,
      urlImg :'',

      load:false,
      bloqueoPage:false,

      idLider: '',
   
      totalCat:0,
      importe:0,
      descuento:0,
      ahorro:0,
      comisionable:0,
      saldo:0,
      totalPag:0,
      respSaldo:0,
      

      opendialog:false,
      opendialogFac:false,
      opendialogInv:false,

      Codart:'',
      TipoLinea:'',
      IdPromo:0,
      IdPromoMXD:0,
      CsetVariable:0,
      moneda:'',

      dialogDelete:false,      
      dialogEnvio:false,
      dialogPedVacio:false,
      dialogSaldo:false,
      dialogEstatusPed :false,

      openSnack : false,


    }

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)

    this.handleCloseDialogDel = this.handleCloseDialogDel.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.clickConfirmDelete = this.clickConfirmDelete.bind(this)
    this.handleOpenDialogInv = this.handleOpenDialogInv.bind(this)

    this.handleCloseDialogDelFac = this.handleCloseDialogDelFac.bind(this)
    this.handleConfirmaFac = this.handleConfirmaFac.bind(this)
    this.handleFacturacion = this.handleFacturacion.bind(this)

    this.getProductos = this.getProductos.bind(this)  
    this.addActPedido = this.addActPedido.bind(this)  

    this.muestraNotificacion = this.muestraNotificacion.bind(this);

  }

    handleDrawerOpen = () => {
      this.setState({open:true})
    }
    handleDrawerClose = () => {
      this.setState({open:false})
    }
    componentDidMount(){
      const key = localStorage.getItem("Authorization")      
      const jwtkey = jwt(key)
      this.setState({idLider:jwtkey.user.id,pais:jwtkey.user.idPais,idCdz:jwtkey.user.idCdz})
      const pedido = localStorage.getItem("Pedido")  
      this.setState({idPedido:pedido})

      this.setState({moneda : Moneda[jwtkey.user.idPais-1].moneda})

      this.setState({urlImg : Imagenes[jwtkey.user.idPais-1].Imagenes+'/img/Catalogo/'})

      if(pedido){     
          /*obtener detalle de pedido*/
           controller.fetchPedido(pedido,jwtkey.user.idPais.toString())
           .then(res=>{ 
                this.setState({lstProd:res})  
              /*obtener numero de lineas y monto de pedido*/
                setTimeout(()=>{
                  controller.fetchPedidoNumItems(pedido ,jwtkey.user.idPais.toString())
                  .then(res=>{
                    this.setState({numItems:res.NumItems,
                      totalCat:res.TotalCat,
                      importe:res.Importe,
                      descuento:res.Descuento,
                      ahorro:res.Ahorro,
                      comisionable:res.Comisionable,
                      saldo:res.SaldoFav,
                      totalPag:res.Pago,
                      muestraItems:true,
                      respSaldo:res.SaldoFav                
                    })  
                  })
                }, 600)

                this.setState({bloqueoPage:true})   
          })  
          .catch(()=>{     
            this.props.history.push(`/Tienda`)
          })
        }else{  
          this.props.history.push(`/Tienda`)
        }
    }
    getProductos(classes){      
      if(this.state.lstProd.length>0){
        return this.state.lstProd.map(prod =>        
        <Grid item key={prod.NumLin}  xs={12} sm={3} >          
              <Producto classes = {classes} 
              IdCodigo = {prod.NumLin}
              Descripcion = {prod.Descripcion}
              Codigo = {prod.Codart}
              PreUniIICat = {prod.PreUniIICat}
              PreUniII = {prod.PreUniII}
              QtyPed = {prod.QtyPed}
              MontoLinII = {prod.MontoLinII}
              Descuento = {prod.Descuento}
              Moneda = {this.state.moneda}
              TipoLinea={prod.TipoLinea}
              IdPromo={prod.IdPromo}
              IdPromoMXD={prod.IdPromoMXD}
              CsetVariable={prod.CsetVariable}
              Pais = {this.state.pais}
              IdCdz = {this.state.idCdz}
              Load = {false}
              Imagen = {this.state.urlImg + prod.Codart+'.jpg'}

              clickConfirmDelete = {this.clickConfirmDelete} 
              addActPedido = {this.addActPedido} 
              handleOpenDialogInv = {this.handleOpenDialogInv}
              
              />      
              <div className={classes.appBarSpacer} /> 
        </Grid>        
        ) 
      }

    }

    clickConfirmDelete(Codart,TipoLinea,IdPromo,IdPromoMXD,CsetVariable){
      this.setState({opendialog:true})
      /*coloca las variables en el state para que las tome en caso e confirmar*/
      this.setState({ Codart:Codart,TipoLinea:TipoLinea,IdPromo:IdPromo,IdPromoMXD:IdPromoMXD,CsetVariable:CsetVariable}) 
    }

    handleDelete = () => {
      this.setState({opendialog:false})
      /*Eliminar registro de pedido*/
      controller.fetchdeletePedidoDet(this.state.pais.toString(),this.state.idPedido,this.state.Codart.toString(),this.state.TipoLinea,this.state.IdPromo,this.state.IdPromoMXD,this.state.CsetVariable)
        .then(res=>{         
          this.muestraNotificacion('El producto fue eliminado con éxito. ',2)
          this.setState({Codart:'',TipoLinea:'',IdPromo:0,IdPromoMXD:0,CsetVariable:0})           
          /*obtener detalle de pedido*/          
          const data = controller.fetchPedido(this.state.idPedido.toString(),this.state.pais.toString())
          data.then(res=>{              
              setTimeout(()=>{  
                this.setState({lstProd:res})    
                    /*obtener numero de lineas y monto de pedido*/
                    controller.fetchPedidoNumItems(this.state.idPedido.toString(),this.state.pais.toString())
                    .then(res=>{   
                          this.setState({numItems:res.NumItems,
                          totalCat:res.TotalCat,
                          importe:res.Importe,
                          descuento:res.Descuento,
                          ahorro:res.Ahorro,
                          comisionable:res.Comisionable,
                          saldo:res.SaldoFav,
                          totalPag:res.Pago,
                          muestraItems:true})               
                        
                    })
              }, 500)  
          })

        })

    }
    
    addActPedido = (lstProd,Codigo) => {     
        this.setState({lstProd:lstProd})
        /*obtener numero de lineas y monto de pedido*/
        setTimeout(()=>{
          controller.fetchPedidoNumItems(this.state.idPedido.toString(),this.state.pais.toString())
        .then(res=>{     
            this.setState({numItems:res.NumItems,
            totalCat:res.TotalCat,
            importe:res.Importe,
            descuento:res.Descuento,
            ahorro:res.Ahorro,
            comisionable:res.Comisionable,
            saldo:res.SaldoFav,
            totalPag:res.Pago,
            muestraItems:true})    
            
            this.muestraNotificacion('Producto agregado : '+ Codigo,1)

          })
        }, 500)

    } 
    handleCloseDialogDel = () => {
      /*cierra ventana sin eliminar registro*/
      this.setState({opendialog:false})
      this.setState({Codart:'',TipoLinea:'',IdPromo:0,IdPromoMXD:0,CsetVariable:0}) 
    }
    handleOpenDialogInv = () => {

     // this.setState({opendialogInv:true})       
      this.muestraNotificacion('Producto sin existencia. ',3)
    }    
    handleCloseDialogInv = () => {
      /*cierra ventana sin eliminar registro*/
      this.setState({opendialogInv:false})       
    }
    handleConfirmaFac =()=>{
      /*abre ventana de confirmacion*/
      this.setState({opendialogFac:true})
    }
    handleCloseDialogDelFac = () => {
      /*cierra ventana si no confirma la facturacion*/
      this.setState({opendialogFac:false})
    }

    handleFacturacion =() =>{

      /*valida se deben actualizar los datos de lider*/
      const key = localStorage.getItem("Authorization")
      const jwtkey = jwt(key)   
      controller.fetchgetActualizaDatos(jwtkey.user.id,jwtkey.user.idPais.toString())
      .then(res=>{                         
           if(res.ActDatos === 0){
            this.setState({actInfoLider : true})       
        }  
      })   



      /*realiza el proceso de facturacion*/
      this.setState({opendialogFac:false})
      if(this.state.importe !== 0){  
        setTimeout(()=>{
          controller.fetchgetValidaEstatusPedido(this.state.idPedido.toString(),this.state.pais.toString())
          .then(res=>{
              if(res.data.data.Estatus[0].Estatus === 1){
                  /*Valida si el pedido cuenta con un saldo a favor*/
                  controller.fetchPedidoNumItems(this.state.idPedido.toString(),this.state.pais.toString())
                  .then(res=>{
                      this.setState({saldo:res.SaldoFav, totalPag:res.Pago})  
                      /*valida si cambio el saldo a favor del cliente antes de enviar el pedido*/  
                      if(res.SaldoFav !== this.state.respSaldo){
                        /*Asigna nuevo saldo en el estado de respaldo*/
                        this.setState({respSaldo:res.SaldoFav})
                        this.setState({dialogSaldo:true})
                        setTimeout(()=>{         
                            this.setState({dialogSaldo:false})
                        }, 3000) 
                      }else{
                          /*Envia pedido a websiz*/
                          controller.fetchpostEnviarPedido(this.state.idPedido.toString(),this.state.pais.toString())
                          .then(res=>{ 
                                controller.fetchpostEnviarNotificacionPedido(this.state.idPedido.toString(),this.state.moneda.toString(),this.state.pais.toString())
                                .then(resNot=>{ 
                                  this.setState({dialogEnvio:true})
                                    setTimeout(()=>{         
                                        this.setState({dialogEnvio:false})
                                        localStorage.removeItem('Pedido') 
                                        this.setState({numItems:'' ,muestraItems : false,rows :[] ,Importe:0 ,idPedido:0}) 
                                        this.props.history.push(`/Tienda`)
                                    }, 2000)                                 
                                })
                          })
                      }                  
                  })
              }else{
                this.setState({dialogEstatusPed:true})
                setTimeout(()=>{     
                    this.setState({dialogEstatusPed:false})
                    localStorage.removeItem('Pedido') 
                    this.setState({numItems:'',muestraItems : false,rows :[],Importe:0,idPedido:0})    
                    this.props.history.push(`/Tienda`)

                }, 2000) 

     
              }


            })         
        }, 600)


      }else{
        // this.setState({dialogPedVacio:true})
        // setTimeout(()=>{         
        //     this.setState({dialogPedVacio:false})
        // }, 1000) 
        this.muestraNotificacion(' No puedes enviar un pedido vacío.',2)
      }



    }

    getactInfoLider(classes){ 
      return     <DatosLider classes = {classes} Lider = {this.state.idLider} Pais = {this.state.pais.toString()}/>               
   }


    /*notificacion*/
    muestraNotificacion = (message,tipo) => {    
      const atrubutos = {position: "top-right",autoClose: 1500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true, draggable: true,}                
      if (tipo === 1){
          toast.success(`${message}`,atrubutos)
      }else if(tipo === 2){
          toast.error(`${message}`,atrubutos)
      }else if(tipo === 3){
          toast.warn(`${message}`,atrubutos)
      }else{
          toast.info(`${message}`,atrubutos)
      }
      return
  }


    render(){
        const {classes,onToggleDark} = this.props
        return (
         
          <div>
          {!this.state.bloqueoPage && <Bloqueo/>}
          
          <div className={classes.root}>
            <CssBaseline />
            <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark} numItems={this.state.numItems}muestraItems={this.state.muestraItems} />
            <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />          
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />        

              {!this.state.actInfoLider &&     
                <Container maxWidth="xl" className={classes.container}>
                  <Grid  xs = {12} sm = {9} >  
                    <Grid item container  >
                            <Grid item  xs={6} sm = {4} >
                              <Typography variant='h4' component="p" >Pedido:</Typography> 
                              
                            </Grid>  
                            <Grid item  xs={2} sm = {4} >
                              <Typography variant='h4' component="p"> {this.state.idPedido}</Typography>
                              
                            </Grid>  
                          </Grid> 
                        
                  </Grid>  
                  <div className={classes.appBarSpacerShort} />
                  <div className={classes.appBarSpacerShort} />

                  <Grid  xs = {12} >  
                      <Grid item container >
                        <Grid item  xs = {11} sm = {6}  > 
                              <Grid item container>
                                <Grid item xs={5} >
                                  <Typography variant='h6' component="p">Catalogo: </Typography> 
                                  <Divider  />
                                </Grid>
                                <Grid item xs={2} >
                                  <Typography variant='h6' component="p"> {this.state.moneda}</Typography> 
                                  <Divider  />
                                </Grid>  
                                <Grid item xs={1} >
                                  <Typography variant='h6' component="p">  {this.state.totalCat}</Typography> 
                                  <Divider  />
                                </Grid>  
                              
                              </Grid>
                              <Grid item container >
                                <Grid item xs={5} >
                                  <Typography variant='h6' component="p">Descuento: </Typography> 
                                  <Divider  />
                                </Grid>
                                <Grid item xs={2} >
                                  <Typography variant='h6' component="p"> %</Typography> 
                                  <Divider  />
                                </Grid> 
                                <Grid item xs={1} >
                                  <Typography variant='h6' component="p"> {this.state.descuento}</Typography> 
                                  <Divider  />
                                </Grid>   
                              </Grid>  
                              <Grid item container >
                                <Grid item xs={5} >
                                  <Typography variant='h6' component="p">Total: </Typography> 
                                  <Divider  />
                                </Grid>
                                <Grid item xs={2} >
                                  <Typography variant='h6' component="p"> {this.state.moneda}</Typography> 
                                  <Divider  />
                                </Grid> 
                                <Grid item xs={1} >
                                  <Typography variant='h6' component="p">{this.state.importe}</Typography> 
                                  <Divider  />
                                </Grid>   
                              </Grid>
                              <Grid item container >
                                <Grid item xs={5} >
                                  <Typography variant='h6' component="p" >                             
                                    <Box color="#00c853">   Saldo (+):</Box>                                
                                    </Typography> 
                                  <Divider  />
                                </Grid>
                                <Grid item xs={2} >
                                  <Typography variant='h6' component="p"> 
                                    <Box color="#00c853">{this.state.moneda}</Box>  
                                  </Typography> 
                                  <Divider  />
                                </Grid> 
                                <Grid item xs={1} >
                                  <Typography variant='h6' component="p">
                                  <Box color="#00c853">{this.state.saldo}</Box>  
                                </Typography> 
                                  <Divider  />
                                </Grid>   
                              </Grid>
                              <Grid item container >
                                <Grid item xs={5} >
                                  <Typography variant='h6' component="p">Pago: </Typography> 
                                  <Divider  />
                                </Grid>
                                <Grid item xs={2} >
                                  <Typography variant='h6' component="p"> {this.state.moneda}</Typography> 
                                  <Divider  />
                                </Grid> 
                                <Grid item xs={1} >
                                  <Typography variant='h6' component="p">{this.state.totalPag}</Typography> 
                                  <Divider  />
                                </Grid>   
                              </Grid>
                        </Grid>                 

                        <Grid item  xs = {11} sm = {6} >     
                              <Grid item container  >
                                  <Grid item xs={5} >
                                    <Typography variant='h6' >Monto A: </Typography> 
                                    <Divider  />
                                  </Grid>
                                  <Grid item xs={2} >
                                    <Typography variant='h6'> {this.state.moneda} </Typography> 
                                    <Divider  />
                                  </Grid>  
                                  <Grid item xs={1}>
                                    <Typography variant='h6' > {this.state.comisionable}</Typography>
                                    <Divider  /> 
                                  </Grid>  
                              </Grid>  
                      
                              <Grid item container  >
                                  <Grid item xs={5} >
                                    <Typography variant='h6'  color ="secondary">Ahorra: </Typography> 
                                
                                  </Grid>
                                  <Grid item xs={2} >
                                    <Typography variant='h6'  color ="secondary"> {this.state.moneda} </Typography> 
                                    
                                  </Grid>  
                                  <Grid item xs={1}>
                                    <Typography variant='h6'  color ="secondary"> {this.state.ahorro}</Typography> 
                                  
                                  </Grid>  
                              </Grid>  
                              <div className={classes.appBarSpacerShort} />
                              <div className={classes.appBarSpacerShort} />
                              <Grid item container    >
                                <Grid item xs={4}  >
                                    <Button  variant="outlined" size='small'  color="secondary" onClick={this.handleConfirmaFac} title="Enviar">                                         
                                        <Typography  variant='subtitle1'>Confirmar pedido</Typography>  

                                    </Button>  
                                </Grid>  
                                <Grid item xs={4}  >

                                    <Button color="secondary" title="Volver a la tienda"  component={Link} to='/Tienda'>   
                                        <StorefrontIcon color="inherit"  style={{ fontSize: 38,  }} />  
                                    </Button> 

                                </Grid>  
                              </Grid>  
                        </Grid> 
                        </Grid> 
                  </Grid>   
                        
                  <div className={classes.appBarSpacerShort} />
                  <div className={classes.appBarSpacerShort} />
                  
                  <Grid container xs={12}>
                      <Grid item xs={12} container spacing={2} >     
                            { this.getProductos(classes)}
                      </Grid>
                  </Grid>    

                    <Dialog open={this.state.opendialog} onClose={this.handleCloseDialogDel}     >
                          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Confirmación
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Desea elimianr el producto seleccionado
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleCloseDialogDel} color="secondary">
                              Cancel
                            </Button>
                            <Button onClick={this.handleDelete} color="secondary">
                              Confirmar
                            </Button>
                          </DialogActions>
                        </Dialog>
                    <Dialog open={this.state.opendialogFac} onClose={this.handleCloseDialogDelFac}     >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Confirmación
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Por favor confirme si desea enviar su pedido 
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleCloseDialogDelFac} color="secondary">
                          Cancel
                        </Button>
                        <Button onClick={this.handleFacturacion} color="secondary">
                          Confirmar
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.opendialogInv} onClose={this.handleCloseDialogInv}>
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Inventario
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                              Producto sin existencia
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialogInv} color="secondary">
                            Aceptar
                            </Button>                            
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.dialogDelete} >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Aviso
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          El producto fue eliminado con éxito.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Dialog open={this.state.dialogEnvio} >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Confirmación
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          El pedido fue enviado con éxito.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Dialog open={this.state.dialogPedVacio} >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Aviso
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          No puedes enviar un pedido vacío.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <Dialog open={this.state.dialogSaldo} >
                          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Aviso
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Se modifico el saldo a favor de tu pedido, por favor verifica el monto a que vas a pagar.
                            </DialogContentText>
                          </DialogContent>
                        </Dialog>
              
                    <Dialog open={this.state.dialogEstatusPed} >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Aviso
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        Excediste el tiempo límite para enviar tu pedido, por favor ingresa a la tienda nuevamente para generar un nuevo pedido.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
              

                    <ToastContainer/>       
                
                </Container> 
              }
                
              {this.state.actInfoLider && 
                  this.getactInfoLider(classes)
              
              }

            </main>
    
          </div>

          </div> 
        )
    }
}
export default (withStyles(useStyles)(Pedido))
