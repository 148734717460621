//import { ClientRequest } from "http";

const drawerWidth = 250;

const useStyles = (theme) => ({
  
    root: {
      display: 'flex',
    },
    
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      backgroundColor:'#E62047',
    //backgroundColor:'#000000'

     
  
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,        
        }
        ),
        
    },
    appBarShift: {
     
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
   
    },
    menuButton: {
      marginRight: 5,
    },
    menuButtonHidden: {
      display: 'none',
    },


    title: {
      flexGrow: 1,
    },
    drawerPaper: {      
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {        
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },

    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },

    appBarSpacerShort: 
     {
      
      height: '10px',
      
    },

    
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
   
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      
    },

    paperCard: {

      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),  
    },
    paperCardPromo: {
      // backgroundColor:'#ffeb3b',
      backgroundColor:'#ff9800',      
      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),  
    },

    paperCardPaq: {
      backgroundColor:'#4caf50',
      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),  
    },


    paperPed: {
      backgroundColor:'#f5f5f5',
    },

    fixedHeight: {
      height: 240,
    },
    footer: {
        // padding: theme.spacing(1),
      marginTop: 'auto',
      marginLeft: '5%',
     // backgroundColor: '#ffffff',
      
      position: 'absolute',
      bottom: '0',
      width: '90%',
      height: '10%',
     padding: theme.spacing(2),

  //    background-color: black;
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  // height: 40px;
  // color: white;

    backgroundColor:theme.palette
      

    },



    button: {
      margin: theme.spacing(1),
      background: 'linear-gradient(45deg, #0080FF 93%, #E62047 10%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    buttonpdf: {
      margin: theme.spacing(1),
      color: '#0080FF',
      height: 48,
      padding: '0 18px',
    },


    

    input: {
      display: 'none',
    },
    navcontainer: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(2),
      padding: theme.spacing(15)
    },

    rootP: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    tableHeader:{
      color: '#fff',
      backgroundColor: '#5D5A5A',
      //5D5A5A
      position:'sticky'
    },
   
    noClick: {
      cursor: 'initial',
    },
    avatar: {
      margin: 10,
    },
    redAvatar: {
      margin: 10,
      color: '#fff',    
      backgroundColor:'#E62047'
    },
    tableWrapper: {
      maxHeight: 407,
      overflow: 'auto',
    },
    width100:{
      width: '100%',
    },
    rootSL: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  
    nested: {
      paddingLeft: theme.spacing(5),
    },
    cardHeader: {
      padding:'8px',
    },
    CardContent: {
      padding:'8px',
    },
    CardActions: {
      padding:'4px',
    },

    cardPricing: {
      fontSize:15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    media: {
      height: 4,
      //paddingTop: '56.25%', // 16:9
      paddingTop: '99.25%', 
    },


    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),    
     
    },
    
 
    buttonQty: {   
      marginLeft: theme.spacing(1),
      width: '50%',
    },
    textFieldQty: {      
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: '70%',   
    }, 
    mediaProd: {
      //  paddingTop: '99.25%', 
      height: '150px',
   },

    iconSearch: {   
      marginTop: theme.spacing(1),
      width: '10%',
  
    },


    iconPed: { 
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: '70%', 
    },
    iconDeletePed: { 
      //marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: '70%', 
    },

    flagAvatar: {
      margin: 10,
    },

    iconTop: { 
      width: '10%', 
    },

    textFielDataClie: {      
      width: '70%',   
    }, 

    btnDataClie: {      
      marginTop: theme.spacing(2),
      width: '70%',   
    }, 

    snak: {
      background: '#2196f3'
    },

    imgSizeLogo: { 
      width : "120px" ,
      height : "45px"
    },


})

export default useStyles
