import axios from 'axios'
import url from './url'

const Api = {
    getReclutamientos: async (lider,mes,anio,pais) => {  
            var data = {
            'idLider': lider,
            'mes':mes,
            'anio':anio,
            'pais': pais
        }
        return await axios.post(url+'/reportes/reclutamientos',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    }, 
    getEmprendedores: async (lider, estatus, pais) =>{
        var data = {
            'idLider': lider,
            'estatus' : estatus, 
            'pais': pais
        }
        return await axios.post(url+'/reportes/emprendedoresAI', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }, 
    getCumpleanios: async (lider, mes, pais) =>{
        var data = {
            'idLider': lider,
            'mes' : mes, 
            'pais': pais
        }
        return await axios.post(url+'/reportes/cumpleanios', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getVtaxPatro: async (lider,mes,anio,pais) => {  
        var data = {
        'idLider': lider,
        'mes':mes,
        'anio':anio,
        'pais': pais
    }
    return await axios.post(url+'/reportes/ventasPatro',
        data,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
    ) 
    },
    getVtaxPatroDet: async (lider,mes,anio,pais) => {  
        var data = {
        'idLider': lider,
        'mes':mes,
        'anio':anio,
        'pais': pais
        }
    return await axios.post(url+'/reportes/ventasPatroDet',
        data,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
        ) 
    },
    getdesglosePuntos: async (lider,campana,tipo,pais) => {  
        console.log(lider,campana,tipo,pais)
        var data = {
        'idLider': lider,
        'campana':campana,
        'tipo':tipo,
        'pais': pais
    }
    return await axios.post(url+'/reportes/desglosePuntos',
        data,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
        }
    ) 
    },
    getCampanadesglosePuntos: async (pais) => {         
        return await axios.get(url+`/reportes/campanadesglosePuntos/${pais}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        )
    },
    getCreditosLider: async (lider,pais) => {         
        return await axios.get(url+`/reportes/getCreditosLider/${lider}/${pais}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        )
    },
    getCreditosLiderTotal: async (lider,pais) => {         
        return await axios.get(url+`/reportes/getCreditosLiderTotal/${lider}/${pais}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        )
    }



}
export default Api