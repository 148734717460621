import React, {useState}from 'react';
import {BrowserRouter as Router,Route} from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'


import Login from './components/Login/index';
import Dashboard from './components/Dashboard/index';
import Catalogo from './components/Catalogo/index';
import RptReclutamientos from './components/Reportes/RptReclutamientos/index';
import RptEmprendedores from './components/Reportes/RptEmprendedores';
import RptCumpleanios from './components/Reportes/RptCumpleanios';
import RptCierreLider from './components/Reportes/RptCierreLider';
import RptVentasporPat from './components/Reportes/RptVentasporPat';
import RptGananciasLider from './components/Reportes/RptGananciasLider';
import RptDesglosePuntos from './components/Reportes/RptDesglosePuntos';
import RptCreditosLider from './components/Reportes/RptCreditosLider';

import Tienda from './components/Carrito/Tienda';
import EdoCta from './components/EdoCta';
import Estrategias from './components/Estrategias/index';
import Pedido from './components/Carrito/Pedido/index';
import Seguimiento from './components/Carrito/Seguimiento/index';

const App = ()=> {

    // We keep the theme in app state
    const [theme, setTheme] = useState({
      palette: {
        type: "light"
      },  
      ///Segoe UI,Tahoma
      typography : { fontFamily: [
          'ClementePDae-Light',
        ].join(',') ,fontSize: 14,
      },   
    });

    
  
    // we change the palette type of the theme in state
    const toggleDarkTheme = () => {
      let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
      setTheme({
        palette: {
          type: newPaletteType
        },
        typography : { fontFamily: [
            'ClementePDae-Light',
          ].join(','),fontSize: 14,}, 
      });
    };

    const muiTheme = createMuiTheme(theme);

  return (
    <div className="App">        
 
      <MuiThemeProvider theme={muiTheme} >  
        <Router>
          <Route path='/' exact component = {Login} />
          {/* <Route path='/Dashboard' exact component = {Dashboard}/>    */}

          <Route path='/Dashboard' exact render = {(props) => <Dashboard {...props } onToggleDark={toggleDarkTheme} />}/>   
          <Route path='/Catalogo' exact render = {(props) => <Catalogo {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptReclutamientos' exact render = {(props) => <RptReclutamientos {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptEmprendedores' exact render = {(props) => <RptEmprendedores {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptCumpleanios' exact render = {(props) => <RptCumpleanios {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptCierreLider' exact render = {(props) => <RptCierreLider {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptVentasporPat' exact render = {(props) => <RptVentasporPat {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptGananciasLider' exact render = {(props) => <RptGananciasLider {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/EdoCta' exact render = {(props) => <EdoCta {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/Estrategias' exact render = {(props) => <Estrategias {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/Tienda' exact render = {(props) => <Tienda {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/Pedido' exact render = {(props) => <Pedido {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/Seguimiento' exact render = {(props) => <Seguimiento {...props } onToggleDark={toggleDarkTheme} />}/> 
          <Route path='/RptDesglosePuntos' exact render = {(props) => <RptDesglosePuntos {...props } onToggleDark={toggleDarkTheme} />}/>   
          <Route path='/RptCreditosLider' exact render = {(props) => <RptCreditosLider {...props } onToggleDark={toggleDarkTheme} />}/> 
          
        </Router>  
      </MuiThemeProvider>
 
    </div>
  );
}

export default App;
