import API from '../api/Reportes';

const Controller = {
    fetchDataReclutamientos: async(lider,mes,anio,pais) =>{ 
        return await API.getReclutamientos(lider,mes,anio,pais)     
        .then(resp => {  
            return resp
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchDataEmprendedores: async(lider, estatus, pais) =>{   
        return await API.getEmprendedores(lider, estatus, pais)     
        .then(resp => {
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataCumpleanios: async(lider, mes, pais) =>{   
        return await API.getCumpleanios(lider, mes, pais)     
        .then(resp => {
            if (resp.data.success) {       
                return  resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataVtaxPatro: async(lider,mes,anio,pais) =>{ 
        return await API.getVtaxPatro(lider,mes,anio,pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchDataVtaxPatroDet: async(lider,mes,anio,pais) =>{ 
        return await API.getVtaxPatroDet(lider,mes,anio,pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    },
    fetchDataDesglosePuntos: async(lider,campana,tipo,pais) =>{ 
        return await API.getdesglosePuntos(lider,campana,tipo,pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchDataCampanadesglosePuntos: async(pais) =>{ 
        return await API.getCampanadesglosePuntos(pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchDataCreditosLider: async(lider,pais) =>{ 
        return await API.getCreditosLider(lider,pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchDataCreditosLiderTotal: async(lider,pais) =>{ 
        return await API.getCreditosLiderTotal(lider,pais)     
        .then(resp => {      
            if (resp.data.success === true) {       
                return  resp               
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }  
}     
export default Controller
