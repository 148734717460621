import React, { Component } from 'react'

import ReactStoreIndicator from 'react-score-indicator'

class Score extends Component {
  render () {

    let ValueScore = this.props.data.PorcentA
    if(ValueScore === 0){
        ValueScore = 0.01
    }
    return (
      <ReactStoreIndicator
        value={this.props.data.PorcentA}
        maxValue={100}
        lineGap = {10}
        lineWidth = {3}
        fadedOpacity = {5}
        width ={130}
      />
    )
  }
}

export default  Score