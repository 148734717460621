import React, { Component } from 'react'

import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'


class CircularProBar extends Component {
    constructor(props){
        super(props)   
        this.state = {
            BgColor:'#FF3333'
        }
    }


    componentDidMount(){
        if(this.props.data.PorcentA >= 40 && this.props.data.PorcentA < 80 ){
            this.setState({BgColor:'#EC8000'})
        }else  if(this.props.data.PorcentA >= 80  ){
            this.setState({BgColor:'#7EC62F'})
        }


    }


  render () {



    return (
        <CircularProgressbar  
        value={this.props.data.PorcentA} 
        text={`${this.props.data.PorcentA}%`}

        styles={buildStyles({
            // Text size
            textSize: '22px',
            // Colors
             pathColor: this.state.BgColor,
             textColor: this.state.BgColor,
          })}
       
    
        />
    )
  }
}

export default  CircularProBar
