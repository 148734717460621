import React from "react";
import TablaEmprendedor from '../../Table/index';

class emprendedor extends React.PureComponent {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'PATROCINADOR', label: 'ID Patrocinador', minWidth: 100 },
                    { id: 'IDCLIEPROV', label: 'ID Cliente', minWidth: 100 },
                    { id: 'NOMBRE', label: 'Nombre', minWidth: 120 },
                    { id: 'DESCRIPCION', label: 'Categoría', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'FECHA_INGRESO', label: 'Fecha de Ingreso', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'MONTOCAT', label: 'Monto Catálogo', minWidth: 120, format: value => value.toFixed(2),align: 'right',},
                    { id: 'FACTURADO', label: 'Facturado', minWidth: 120, format: value => value.toFixed(2),align: 'right',},
                    { id: 'SALDO', label: 'Saldo', minWidth: 120, format: value => value.toFixed(2),align: 'right',},
                    { id: 'COMISIONABLE', label: 'Comisionable C/IVA', minWidth: 120, format: value => value.toFixed(2),align: 'right',},
                    { id: 'S_IVA', label: 'Comisionable S/IVA', minWidth: 120, format: value => value.toFixed(2),align: 'right',},
                  ]}
              />
          );
    }
  }

  export default emprendedor