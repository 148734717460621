import React, { Component } from 'react';
import jwt from 'jwt-decode';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import SideBar from '../../SideBar/index';
import TopBar from '../../TopBar/index';
import Typography from '@material-ui/core/Typography';
import ControllerProd from '../../../controllers/Productos';
import controllerPed from '../../../controllers/Pedidos';

import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import useStyles from '../../../styles/ui-styles';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import Loading from '../../Loading/index';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import FilterListIcon from '@material-ui/icons/FilterList';
import Moneda from '../../../config/general'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Producto from '../CardProducto/index'
import DatosLider from '../../DatosLider/index'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StepImg from '../../StepImg/index'
import StepsAyuda from '../../../config/StepsAyuda'

import Snackbar from '@material-ui/core/Snackbar';
import Bloqueo from '../../BloqueoPage/index'

import Imagenes from '../../../config/general'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class Tienda extends Component{

    constructor(props){
        super(props)
        this.state = {
            open:false,
            lstProd: [],
            lstProdFiltro: [],
            lstProdBk: [],
            response: true,
            idLider: '',
            pais:'',
            idCdz:0,
            urlImg :'',

            xpaquete: false,
            todos : true,
            xpromo : false,
            idPedido:0,

            numItems:'',
            muestraItems : false,
            opendialogInv:false,

            load:false,
            bloqueoPage:false,

            currentPage: 1,
            maxNumPage: 8,
            indexOfFirst: 0,
            indexOfLast: 8,
            maxPage: 0,
            maxPageBk: 0,

            actFiltro :false,
            pages:[],
            pagesBk:[],
            
            moneda:'',

            openFiltros: false,
            descFiltro:'',

            actInfoLider:false,

            mensajeAdd:'',
            opendialogProdAdd:false,

            opendialogAyuda:false,

            openSnack : false,
            descSnack : "",

        };

        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.getProductos = this.getProductos.bind(this);
        this.handleCloseDialogInv = this.handleCloseDialogInv.bind(this);
        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleChangeCodigo = this.handleChangeCodigo.bind(this);
        
        this.handleChangePaquetes = this.handleChangePaquetes.bind(this);
        this.handleChangePromos = this.handleChangePromos.bind(this);
        this.addProducto = this.addProducto.bind(this);
        this.getPages = this.getPages.bind(this);

        this.PagesBtn = this.PagesBtn.bind(this);

        this.handleCloseDialogAyuda = this.handleCloseDialogAyuda.bind(this);
        this.handleCloseSnak = this.handleCloseSnak.bind(this);
  
        this.muestraNotificacion = this.muestraNotificacion.bind(this);
  

    }
  
    componentDidMount(){

        const key = localStorage.getItem("Authorization")
        const jwtkey = jwt(key)   

        /*valida se deben actualizar los datos de lider*/
        controllerPed.fetchgetActualizaDatos(jwtkey.user.id,jwtkey.user.idPais.toString())
        .then(res=>{             
            if(res.ActDatos === 0){
                this.setState({actInfoLider : true})       
            }        
        })       
        this.setState({idLider:jwtkey.user.id,pais:jwtkey.user.idPais,idCdz:jwtkey.user.idCdz})
        this.setState({moneda : Moneda[jwtkey.user.idPais-1].moneda})
        this.setState({urlImg : Imagenes[jwtkey.user.idPais-1].Imagenes+'/img/Catalogo/'})


        /*obtener numero de lineas y monto de pedido*/
        const pedido = localStorage.getItem("Pedido")  
        if(pedido){
    
            setTimeout(()=>{  
                controllerPed.fetchPedidoNumItems(pedido,jwtkey.user.idPais.toString())
                .then(res=>{   
                   
                    this.setState({numItems:res.NumItems,
                        muestraItems:true
                    })         
                    
                }) 
            }, 800)
        }
        /*Obtiene lista de prodctos*/
        setTimeout(()=>{     
            ControllerProd.fetchDataProductos(jwtkey.user.idPais.toString(),jwtkey.user.idCdz.toString())
            .then(res=>{                     
                      
                this.setState({maxPageBk: Math.round(res.data.data.productos.length/this.state.maxNumPage)})
                this.setState({maxPage: Math.round(res.data.data.productos.length/this.state.maxNumPage)})
                this.setState({lstProdBk:res.data.data.productos, response:true})
                this.setState({lstProdFiltro:res.data.data.productos, response:true})  
                this.setState({lstProd:res.data.data.productos.slice(this.state.indexOfFirst,this.state.indexOfLast),response:true})


                this.setState({descFiltro:'Todos'})
                this.setState({load:true})  
             
                const ArrPage = []
                for(let i = 1; i <= Math.round(res.data.data.productos.length/this.state.maxNumPage); i++) {              
                    ArrPage.push(i)
                }
        
                this.setState({pages:ArrPage.slice(0,5)}) 

                this.setState({bloqueoPage:true}) 

            })
            .catch(()=>{
                this.setState({maxPageBk:0})
                this.setState({maxPage:0})
                this.setState({lstProdBk:[], response:false})
                this.setState({lstProdFiltro:[], response:false})  
                this.setState({lstProd:[],response:false})
                this.setState({descFiltro:'Intenta nuevamente'})
                this.setState({load:true})    
                this.setState({bloqueoPage:true})     
            })
        }, 800)  
    } 


    handleDrawerOpen = () => {
        this.setState({open:true})
    }
    handleDrawerClose = () => {
        this.setState({open:false})
    }

    getProductos(classes){ 
       
        return this.state.lstProd.map(prod =>
            <Grid item key={prod.IdCodigo} xs={12} sm={3}>
                <Producto classes = {classes}
                IdCodigo = {prod.IdCodigo}
                Descripcion = {prod.Descripcion}
                Codigo = {prod.Codigo}
                Precio = {prod.Precio}
                Moneda = {this.state.moneda}
                Imagen = {this.state.urlImg + prod.Imagen+'.jpg'}
                addProducto = {this.addProducto}/>
            </Grid>
        )
    }
    getactInfoLider(classes){ 
        return     <DatosLider classes = {classes} Lider = {this.state.idLider} Pais = {this.state.pais.toString()}/>               
    }
    addProducto (Codigo,Qty)  { 
       
            const pedido = localStorage.getItem("Pedido")      
            if(pedido){
                setTimeout(()=>{     
                    /*valida inventario*/                   
                    ControllerProd.fetchgetConsultaInventario(this.state.pais.toString(),Codigo,Qty,pedido,this.state.idCdz.toString())
                    .then(resinv=>{                       
                        if(resinv === '1'){
                            /*Agrega Producto a pedido*/
                            setTimeout(()=>{    
                            controllerPed.fetchpostInsPedidoDet(pedido,Codigo,Qty,this.state.pais.toString())
                            .then(resPed=>{    
                      
                                /*muestra mensaje en caso de haber algun error*/
                                if(resPed.Agregado === 0){
                                    this.muestraNotificacion('No se pudo agregar el código: '+ Codigo,2)
                                }
                                    /*obtener numero de lineas y monto de pedido*/
                                    setTimeout(()=>{ 
                                        controllerPed.fetchPedidoNumItems(pedido ,this.state.pais.toString())
                                        .then(res=>{   
                                            this.setState({numItems:res.NumItems,
                                            muestraItems:true})
                                            this.muestraNotificacion('Producto agregado : '+ Codigo,1)                                            
                                        })
                                        .catch(()=>{
                                            controllerPed.fetchPedidoNumItems(pedido ,this.state.pais.toString())
                                            .then(res=>{   
                                                this.setState({numItems:res.NumItems,
                                                muestraItems:true})         
                                            })
                                        })     
                                    }, 500)                          

                                })
                                .catch((e)=>{
                                    this.muestraNotificacion('Por favor intenta nuevamente :'+ Codigo,2)
                                })
                            }, 600)  

                        }else if(resinv === '0') {
                            this.muestraNotificacion('Producto sin existencia :'+ Codigo,3)
                        }else{
                            this.muestraNotificacion('Por favor intenta nuevamente :'+ Codigo,2)
                        }
                    })
                    .catch(()=>{
                        this.muestraNotificacion('Por favor intenta nuevamente :'+ Codigo,2)
                    })
                }, 800)  

            }else{
                /*crear pedido*/
                controllerPed.fetchpostInsPedidoCab(this.state.idLider,this.state.pais.toString(),this.state.idCdz.toString())
                .then(res=>{   
                    this.setState({idPedido : res})
                    /*guarda pedido en LS*/
                    window.localStorage.setItem('Pedido',res)      
                    setTimeout(()=>{                      
                        /*valida inventario*/
                        ControllerProd.fetchgetConsultaInventario(this.state.pais.toString(),Codigo,Qty,res,this.state.idCdz.toString())
                        .then(resinv=>{                          
                            if(resinv === '1'){
                                /*Agrega Producto a pedido*/
                                controllerPed.fetchpostInsPedidoDet(res,Codigo,Qty,this.state.pais.toString())
                                .then(resPed=>{                               
                                    /*muestra mensaje en caso de haber algun error*/
                                    if(resPed.Agregado === 0){
                                        this.muestraNotificacion('Por favor intenta nuevamente -'+ Codigo,2)
                                    }
                                    /*obtener numero de lineas y monto de pedido*/
                                    setTimeout(()=>{ 
                                        controllerPed.fetchPedidoNumItems(this.state.idPedido ,this.state.pais.toString())
                                        .then(res=>{                                    
                                            this.setState({numItems:res.NumItems,
                                            muestraItems:true}) 
                                            this.muestraNotificacion('Producto agregado : '+ Codigo)
                                        })    
                                    }, 500)                        
                                })
                            }else if(resinv === '0') {
                                this.muestraNotificacion('Producto sin existencia :'+ Codigo,3)
                            }else{
                               this.muestraNotificacion('Por favor intenta nuevamente :'+ Codigo,2)
                            }
                        }).catch(()=>{
                            this.muestraNotificacion('Por favor intenta nuevamente :'+ Codigo,2)
                        })
                    }, 500)  
                })
                .catch(()=>{
                    localStorage.removeItem('Pedido') 
                })         

            }
    }



    handleChangeAll = ()=>{
        this.setState({currentPage:1}) 
        setTimeout(()=>{   
            this.setState({maxPage:this.state.maxPageBk})
            this.setState({lstProdFiltro:this.state.lstProdBk})
            this.setState({lstProd:this.state.lstProdFiltro.slice(this.state.indexOfFirst,this.state.indexOfLast)})
        }, 500)        
        this.setState({todos:true, xpaquete:false,  xpromo: false})
        this.setState({openFiltros:false}) 
        this.setState({descFiltro:'Todos'})
        this.setState({actFiltro:false}) 

        const ArrPage = []
        for(let i = 1; i <= Math.round(this.state.lstProdBk.length/this.state.maxNumPage); i++) {              
            ArrPage.push(i)
        }
        this.setState({pages:ArrPage.slice(0,5)}) 

    }
    handleChangeCodigo = (e)=>{
        const search = e.target.value      
        this.setState({currentPage:1}) 
        this.setState({actFiltro:true}) 
        
        if(isNaN(search)){     
            const filterDesc = this.state.lstProdBk.filter(Descripcion => Descripcion.Descripcion.toLowerCase().includes(search.toLowerCase()))
            this.setState({lstProdFiltro:filterDesc})
            this.setState({lstProd:filterDesc.slice(this.state.indexOfFirst,this.state.indexOfLast)})
            this.setState({todos:false, xpaquete:false,  xpromo: false})   
            this.setState({maxPage: Math.round(filterDesc.length/this.state.maxNumPage)})          

            const ArrPageDes = []
            for(let i = 1; i <= Math.round(filterDesc.length/this.state.maxNumPage); i++) {              
                ArrPageDes.push(i)
            }
            ArrPageDes.slice(0,5)
            this.setState({pages:ArrPageDes.slice(0,5)}) 

        }else{ 
  
            const filterCodigo = this.state.lstProdBk.filter(Codigo => Codigo.Codigo.includes(search))              
            this.setState({lstProdFiltro:filterCodigo})
            this.setState({lstProd:filterCodigo.slice(this.state.indexOfFirst,this.state.indexOfLast)})
            this.setState({todos:false,  xpaquete:false,  xpromo: false})          
            this.setState({maxPage: Math.round(filterCodigo.length/this.state.maxNumPage)})
            
            const ArrPageCod = []
            for(let i = 1; i <= Math.round(filterCodigo.length/this.state.maxNumPage); i++) {              
                ArrPageCod.push(i)
            }
            ArrPageCod.slice(0,5)
            this.setState({pages:ArrPageCod.slice(0,5)}) 
        } 
        this.setState({descFiltro:search})     
    }
    handleChangePaquetes = ()=>{
        const filterPaq = this.state.lstProdBk.filter(paquete => paquete.TipoProd.includes('P'))
        setTimeout(()=>{   
            this.setState({lstProdFiltro:filterPaq})
            this.setState({lstProd:this.state.lstProdFiltro.slice(this.state.indexOfFirst,this.state.indexOfLast)})
        }, 500)
        this.setState({todos:false,  xpaquete:true,  xpromo: false})
        this.setState({openFiltros:false}) 
        this.setState({descFiltro:'Paquetes'})
        this.setState({currentPage:1})  
        this.setState({actFiltro:true}) 
        const ArrPage = []
        for(let i = 1; i <= Math.round(filterPaq.length/this.state.maxNumPage); i++) {              
            ArrPage.push(i)
        }
        this.setState({pages:ArrPage.slice(0,5)}) 

        this.setState({maxPage: Math.round(filterPaq.length/this.state.maxNumPage)})

    }
    handleChangePromos = ()=>{
        const filterPromo = this.state.lstProdBk.filter(promo => promo.Promocional === 1)
        setTimeout(()=>{   
            this.setState({lstProdFiltro:filterPromo})
            this.setState({lstProd:this.state.lstProdFiltro.slice(this.state.indexOfFirst,this.state.indexOfLast)})
        }, 500)
        this.setState({todos:false,  xpaquete:false,  xpromo: true,})
        this.setState({openFiltros:false}) 
        this.setState({descFiltro:'Promocionales'})
        this.setState({currentPage:1})  
        this.setState({actFiltro:true}) 
        const ArrPage = []
        for(let i = 1; i <= Math.round(filterPromo.length/this.state.maxNumPage); i++) {              
            ArrPage.push(i)
        }
        this.setState({pages:ArrPage.slice(0,5)}) 

        this.setState({maxPage: Math.round(filterPromo.length/this.state.maxNumPage)})


    }
    clickBack = () => {

        setTimeout(()=>{ 
            if(this.state.currentPage > 1){       
                this.setState({currentPage:this.state.currentPage - 1 })         
            }
            const Last = this.state.currentPage * this.state.maxNumPage
            const First  = Last - this.state.maxNumPage    
            const filterPage = this.state.lstProdFiltro.slice(First,Last)       
            if(filterPage){     
                this.setState({lstProd:filterPage})           
            }
        }, 500) 

        setTimeout(()=>{   
            const ArrPage = []
            if(this.state.actFiltro === false){                
                for(let i = 1; i <= Math.round(this.state.lstProdBk.length/this.state.maxNumPage); i++) {              
                    ArrPage.push(i)
                }                  
            }else{   
                for(let i = 1; i <= Math.round(this.state.lstProdFiltro.length/this.state.maxNumPage); i++) {              
                    ArrPage.push(i)
                }                          
            }     
            this.setState({pagesBk:ArrPage})  
            if(this.state.currentPage > 2 && this.state.currentPage < this.state.pagesBk.length ){             
                const filterItemPage = this.state.pagesBk.slice(this.state.currentPage -3,this.state.currentPage + 2) 
                this.setState({pages:filterItemPage})

            }   

        }, 500) 

    }

    clickNext = () => {       

        if(this.state.currentPage < this.state.maxPage){    
            setTimeout(()=>{ 
                this.setState({currentPage:this.state.currentPage + 1 })       
                const Last = this.state.currentPage * this.state.maxNumPage
                const First  = Last - this.state.maxNumPage
                const filterPage = this.state.lstProdFiltro.slice(First,Last)  
                if(filterPage){         
                    this.setState({lstProd:filterPage})
                }else{
                    this.setState({lstProd:this.state.lstProdBk})
                }
            }, 500) 

            setTimeout(()=>{   
                const ArrPage = []
                if(this.state.actFiltro === false){                
                    for(let i = 1; i <= Math.round(this.state.lstProdBk.length/this.state.maxNumPage); i++) {              
                        ArrPage.push(i)
                    }
                    
                }else{   
                    for(let i = 1; i <= Math.round(this.state.lstProdFiltro.length/this.state.maxNumPage); i++) {              
                        ArrPage.push(i)
                    }                          
                }
                this.setState({pagesBk:ArrPage})  
                if(this.state.currentPage > 2 && this.state.currentPage < this.state.pagesBk.length ){ 
                    const filterItemPage = this.state.pagesBk.slice(this.state.currentPage -3,this.state.currentPage + 2) 
                    this.setState({pages:filterItemPage})
                }   

            }, 500)
        }

    }
    clickFilter = () => {     
        this.setState({openFiltros:true}) 
    }
    closeFilter = () => {    
        this.setState({openFiltros:false}) 
    }   
    getPages = () => {   
        
          return this.state.pages.map(page =>    
                this.PagesBtn(page)
          )     
    } 
    PagesBtn  = (page) => {  
        
        return page === this.state.currentPage ?   
              <Button onClick={this.setPages.bind(this,{page} )}   color="secondary"  > 
                  <Typography variant='h5' component="p"> {page} </Typography>
               </Button>  : 
              <Button onClick={this.setPages.bind(this,{page} )} >{page} </Button>

    }     
    setPages = (e) => {  
        setTimeout(()=>{   

            this.setState({currentPage:e.page })   
            const Last = this.state.currentPage * this.state.maxNumPage
            const First  = Last - this.state.maxNumPage
            const filterPage = this.state.lstProdFiltro.slice(First,Last)              
            this.setState({lstProd:filterPage})
          
        }, 500) 

        setTimeout(()=>{   
            if(e.page > 2 && e.page < this.state.pages.length ){                         
                const filterItemPage = this.state.pages.slice(this.state.currentPage -3,this.state.currentPage + 2)  
                this.setState({pages:filterItemPage})
            }
        }, 500) 
    }


    handleCloseDialogInv = () => {
        /*cierra ventana sin eliminar registro*/    
            this.setState({opendialogInv:false})     
    }
    handleCloseDialogAyuda = () => {
        /*cierra ventana de ayuda*/    
            this.setState({opendialogAyuda:false})     
    }
    clickAyuda = () => {     
        this.setState({opendialogAyuda:true}) 
    }


    handleCloseSnak = () => {    
        this.setState({openSnack:false})
    }

    /*notificacion*/
    muestraNotificacion = (message,tipo) => {    
        const atrubutos = {position: "top-right",autoClose: 1500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true, draggable: true,}                
        if (tipo === 1){
            toast.success(`${message}`,atrubutos)
        }else if(tipo === 2){
            toast.error(`${message}`,atrubutos)
        }else if(tipo === 3){
            toast.warn(`${message}`,atrubutos)
        }else{
            toast.info(`${message}`,atrubutos)
        }
        return
    }

    render(){
        const {classes,onToggleDark} = this.props

        return (   
            
          <div>
                {!this.state.bloqueoPage && <Bloqueo/>}

               
                <div className={classes.root}>    
                    <CssBaseline />                    
                    
                    <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark} numItems={this.state.numItems}muestraItems={this.state.muestraItems} />
                
                    <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />       
                    
                    <main className={classes.content}>
                    <div className={classes.appBarSpacer} />               
                    {!this.state.actInfoLider &&      
                        <Container maxWidth="xl" className={classes.container}>

                            <Grid container justify="center" >                        
                                <Grid item xs={12}>                        
                                    <Grid container spacing={2} alignItems="center" justify="center" >
                                        <Grid item >
                                            <Typography variant='h6' component="p" title ='Mostrando' >
                                                    Mostrando:
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography variant='h6' component="p" title = {this.state.descFiltro} >
                                                    {this.state.descFiltro}
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <TextField
                                                id="outlined-name"
                                                label="Producto"
                                                className={classes.textField}
                                                onChange={this.handleChangeCodigo}
                                                margin="normal"
                                                variant="outlined"
                                                color="secondary"
                                                title = {this.state.descFiltro} 
                                            />   
                                        </Grid>
                                        <Grid item >
                                            <Button color="secondary" title="Filtrar">   
                                                <FilterListIcon
                                                    color="inherit"                                   
                                                    style={{ fontSize: 38,  }}
                                                        // color='secondary'
                                                        onClick={this.clickFilter}                                       
                                                    />   
                                            </Button> 
                                        </Grid>
                                        <Grid item >
                                            <Button color="secondary" title="Ayuda">   
                                                <HelpOutlineIcon
                                                    color="inherit"                                   
                                                    style={{ fontSize: 38,  }}
                                                        // color='secondary'
                                                        onClick={this.clickAyuda}                                       
                                                    />   
                                            </Button> 
                                        </Grid> 
                                
                                    </Grid>  
                                </Grid>
                            </Grid>
                            <div className={classes.appBarSpacerShort} />   
                            <Grid container  justify = "center" alignItems="center" spacing={1} >     
                        
                                <Grid item xs={12} container spacing={2} >

                                    {!this.state.load && <Loading/>}
                                    {this.state.load && this.getProductos(classes)}                           
                                    {/* {this.getProductos(classes)} */}

                                </Grid>
                            
                            </Grid>              
                            <div className={classes.appBarSpacerShort} />  
                            <Grid container   justify="center" alignItems="center">       
                                <Grid item  >
                                    <Button color="secondary" title="Anterior">     
                                        <SkipPreviousIcon color="inherit"  style={{ fontSize: 38,  }} 
                                        //  color='secondary'
                                        onClick={this.clickBack}
                                        />
                                    </Button>  
                                </Grid>    
                                <Grid item  >                     
                                    {this.state.load && this.getPages(classes)}
                                </Grid>
                                <Grid item  >           
                                    <Button color="secondary" title="Siguiente">     
                                        <SkipNextIcon color="inherit" style={{ fontSize: 38, }}
                                        //   color='secondary'
                                            onClick={this.clickNext}
                                            />
                                    </Button>              
                                </Grid>
                            </Grid>     

                            <Dialog open={this.state.opendialogInv} onClose={this.handleCloseDialogInv}>
                                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                    Inventario
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                    Producto sin existencia
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseDialogInv} color="secondary">
                                    Aceptar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={this.state.opendialogProdAdd} >
                                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                    Aviso
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                    {this.state.mensajeAdd} 
                                    </DialogContentText>
                                </DialogContent>                        
                            </Dialog>
                            <Dialog open={this.state.opendialogAyuda} onClose={this.handleCloseDialogAyuda}>
                                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                    Como comprar
                                </DialogTitle>
                                <DialogContent>                          
                                    <StepImg  StepDesktop = {StepsAyuda.tutorialStepsDesktop} StepMovil = {StepsAyuda.tutorialStepsMovil}/>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseDialogAyuda} color="secondary">
                                    Cerrar
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <SwipeableDrawer
                                anchor="right"
                                open={this.state.openFiltros}
                                onClick={this.closeFilter}
                            >
                            <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Checkbox checked={this.state.todos} onChange={this.handleChangeAll}/>
                                        Todos
                                    </Grid>                               
                                    <Grid item xs={12}>
                                        <Checkbox checked={this.state.xpaquete} onChange={this.handleChangePaquetes}  />
                                        Paquetes
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Checkbox checked={this.state.xpromo} onChange={this.handleChangePromos}  />
                                        Promocionales
                                    </Grid>
                                </Grid>
                            </SwipeableDrawer>

                       

                            <ToastContainer/>             
                          

                    </Container>
                    }            
                    {this.state.actInfoLider && 
                        this.getactInfoLider(classes)            
                    }
                    </main>    
            </div>
                
         </div> 
    
        )
    }
}

export default (withStyles(useStyles)(Tienda))
