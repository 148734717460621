import React, { Component } from 'react'
import jwt from 'jwt-decode'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import SideBar from '../../SideBar/index'
import TopBar from '../../TopBar/index'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import useStyles from '../../../styles/ui-styles'

import controllerPed from '../../../controllers/Pedidos'
import Moneda from '../../../config/general'
import Pedidos from '../Seguimiento/Pedidos'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'

import Detalle from '../Seguimiento/DetallePed'

//import Bloqueo from '../../BloqueoPage/index'

class Seguimiento extends Component{
    constructor(props){
            super(props)
            this.state = {
            open:false, 
            idLider: '',
            pais :'', 
          
            lstPedidos:[],
            screenMovil:true,
            moneda:'',
            lstPedidoDet:[],
            opendialogDet:false,
            idPedido:0,

            //bloqueoPage:false,
        }
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
        this.handleDrawerClose = this.handleDrawerClose.bind(this)

        this.getPedidos = this.getPedidos.bind(this)  
        this.getPedidosDet = this.getPedidosDet.bind(this)  
        
        this.handleCloseDialog = this.handleCloseDialog.bind(this);


    }

    handleDrawerOpen = () => {
      this.setState({open:true})
    }
    handleDrawerClose = () => {
      this.setState({open:false})
    }
    componentDidMount(){

    if(window.screen.width < 600){
        this.setState({screenMovil:false})
    }else{
        this.setState({screenMovil:true})
    }

    const key = localStorage.getItem("Authorization")      
    const jwtkey = jwt(key)
    this.setState({pais:jwtkey.user.idPais,idLider:jwtkey.user.id})
    this.setState({moneda : Moneda[jwtkey.user.idPais-1].moneda})

     /*obtener pedidos de lider*/
     controllerPed.fetchgetSegPedidos(jwtkey.user.id.toString(),jwtkey.user.idPais.toString())
    .then(res=>{  
        this.setState({lstPedidos:res
        })         
    })

    /*obtener numero de lineas y monto de pedido*/
    const pedido = localStorage.getItem("Pedido")  
        if(pedido){
            controllerPed.fetchPedidoNumItems(pedido,jwtkey.user.idPais.toString())
            .then(res=>{   
                this.setState({numItems:res.NumItems,
                    muestraItems:true
                })  
                //this.setState({bloqueoPage:true})                
            })

             
        }
    }   
    
    
    getPedidosDet = (Pedido) =>{

      /*obtener el detalle del pedido*/
     controllerPed.fetchgetSegPedidosDet(Pedido,this.state.pais.toString())
     .then(res=>{  
  
         this.setState({idPedido:Pedido})    
         this.setState({lstPedidoDet:res})       
         this.setState({opendialogDet:true})             
     })
 

    }
    handleCloseDialog = () => {
        /*cierra ventana de ayuda*/    
            this.setState({opendialogDet:false})     
    }
    getPedidos(classes){  

        if(this.state.lstPedidos.length>0){
          return this.state.lstPedidos.map(pedidos => 
            <Grid item key={pedidos.Pedido}  xs={12} sm={8} >  
                    <Pedidos classes = {classes} 
                    Pedido = {pedidos.Pedido}
                    Fecha = {pedidos.Fecha}
                    Moneda = {this.state.moneda}
                    Importe = {pedidos.Importe}
                    Estatus = {pedidos.Estatus}     
                    getPedidosDet = {this.getPedidosDet}                
                    />
            </Grid> 
               
          ) 
        } 
    }
    render(){
        const {classes,onToggleDark} = this.props
        return (
            <div>
            {/* {!this.state.bloqueoPage && <Bloqueo/>} */}

          <div className={classes.root}>
            <CssBaseline />
            <TopBar classes = {classes} open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} onToggleDark={onToggleDark} numItems={this.state.numItems}muestraItems={this.state.muestraItems} />
            <SideBar classes = {classes} open = {this.state.open} handleDrawerClose = {this.handleDrawerClose} />          
            <main className={classes.content}>
            <div className={classes.appBarSpacer} />        

                <Container maxWidth="xl" className={classes.container}>
                <Grid  xs = {12} sm = {12} >  
                    <Grid item container  >
                        <Grid item  xs={6} sm = {6} >
                            <Typography variant='h4' component="p" >Mis pedidos:</Typography> 
                        </Grid>  
                    </Grid> 
                </Grid>  
                <div className={classes.appBarSpacerShort} />    
                <div className={classes.appBarSpacerShort} />    
                <div className={classes.appBarSpacerShort} />    
                
                <Grid item  xs={12} sm={8} >       
                    <Grid container alignItems="center"  >  
                            <Grid item xs={2} sm={2} align="center">
                                <Typography variant="body1" > Pedido</Typography>
                            </Grid>
                            <Grid item xs={4} sm={2} align="center" >
                                <Typography variant="body1" > Fecha </Typography>
                            </Grid>
                            <Grid item xs={3} sm={2} align="right" >
                                <Typography variant="body1"  > Importe </Typography>
                            </Grid>
                            
                            {this.state.screenMovil && <Grid item xs={3} sm={4} align="center" >
                                <Typography variant="body1" > Estatus </Typography>
                            </Grid>}                        
                        </Grid>  
                    </Grid>  

                <div className={classes.appBarSpacerShort} />    
                <div className={classes.appBarSpacerShort} />    
                
                <Grid container xs={12}>
                    <Grid item xs={12} container spacing={2} >     
                            { this.getPedidos(classes)}
                    </Grid>
                </Grid>    

                <Dialog fullWidth={'sm'}
                    maxWidth={'sm'}
                    open={this.state.opendialogDet} onClose={this.handleCloseDialog}>
                                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Detalle de Pedido : {this.state.idPedido}
                        </DialogTitle>
                        <DialogContent>                          
                            <Detalle  lstPedidoDet = {this.state.lstPedidoDet} Moneda = {this.state.moneda}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="secondary">
                            Cerrar
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Container> 


            </main>
    
         </div>
        
         </div>
        )
    }
}
export default (withStyles(useStyles)(Seguimiento))
