import axios from 'axios'
import url from './url'


const Api = {

    getPedidoNumItems: async (pedido,pais) => { 
        return await axios.get(url+`/pedidos/pedidoNumItems/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getPedido: async (pedido,pais) => { 
        return await axios.get(url+`/pedidos/pedidoDet/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    deletePedidoDet: async (pais,pedido,codigo,tipcom,idpromo,idpromomx,cset) => { 
           return await axios.delete(url+`/pedidos/delPedidoDet/${pais}/${pedido}/${codigo}/${tipcom}/${idpromo}/${idpromomx}/${cset}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    postInsPedidoCab: async (lider,pais,idcdz) =>{
        var data = {
            'idLider': lider,
            'pais': pais,
            'idCdz': idcdz
        }
        return await axios.post(url+'/pedidos/insPedidoCab', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }, 
    postInsPedidoDet: async (pedido,codigo,qty,pais) =>{
        var data = {
            'pedido':pedido,
            'codigo':codigo,
            'qty':qty,
            'pais': pais
        }
        return await axios.post(url+'/pedidos/insPedidoDet', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }, 
    putCancelaPedido: async (pedido,pais) => { 
        return await axios.get(url+`/pedidos/cancelaPed/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    postCerrarPedido: async (pedido,pais) =>{
        var data = {
            'pedido':pedido,
            'pais': pais
        }
        return await axios.post(url+'/pedidos/cierraPedido', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getRemision: async (pedido,pais) => { 
        return await axios.get(url+`/pedidos/remision/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    }, 
    postEnviarPedido: async (pedido,pais) =>{
        var data = {
            'pedido':pedido,
            'pais': pais
        }
        return await axios.post(url+'/pedidos/enviarPedido', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },

    postEnviarNotificacionPedido: async (pedido,moneda,pais) =>{
        var data = {
            'pedido':pedido,
            'moneda':moneda,
            'pais': pais
        }
        return await axios.post(url+'/pedidos/enviarNotificacionPedido', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    getActualizaDatos: async (lider,pais) =>{
        var data = {
            'idLider': lider,
            'pais': pais,
        }
        return await axios.post(url+'/lider/getActualizaDatos', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    },
    
    putActualizaDatos: async (lider,pais,email,telcel) =>{
        var data = {
            'idLider': lider,
            'pais': pais,
            'eMail': email,
            'telCel': telcel
        }
        return await axios.post(url+'/lider/putActualizaDatos', 
        data, {
            headers :{
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")
                }
            }
        )
    }, 
 

    getValidaEstatusPedido: async (pedido,pais) => { 
            return await axios.get(url+`/pedidos/estatusPed/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    }, 

    getSegPedidos: async (lider,pais) => { 
        return await axios.get(url+`/pedidos/seguimientoPed/${pais}/${lider}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getSegPedidosDet: async (pedido,pais) => { 
        return await axios.get(url+`/pedidos/seguimientoPedDet/${pais}/${pedido}`,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    
}
export default Api