
import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';


class ZmtSaldo extends Component{
    constructor(props){
        super(props)   
        this.state = {
            data:{}
        }
    }
    componentDidMount(){    
    }
    render() {             
        return (
        <React.Fragment>
            <br/>
              <Typography component="p" variant="h5" align="center">Saldo actual</Typography>
              <Typography component="p" variant="h6" align="center">{this.props.data.SaldoActual}</Typography>
              <Typography component="p" variant="body1" align="center">Fecha de último pedido : {this.props.data.FecUltPed}</Typography>
              <Typography component="p" variant="body1" align="center">Importe : {this.props.data.MontUltPed}</Typography>
           <br/>
        </React.Fragment>
        )
    }
}

export default ZmtSaldo