import axios from 'axios'
import url from './url'

const Api = {
    getInfoLider: async (lider,pais) => {  
            var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/infoLider',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    }
}
export default Api