
import React from "react";
import TablaEmprendedor from '../../Table/index';

class emprendedor extends React.PureComponent {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }


    render(){
        const rows = this.props.rows;
        return (
              <TablaEmprendedor
                rows = {rows}
                columns={ [
                    { id: 'IDcliente', label: 'ID Cliente', minWidth: 100 },
                    { id: 'NombreCli', label: 'Nombre', minWidth: 120 },
                    { id: 'Descuento', label: 'Descuento', minWidth: 100, format: value => value.toLocaleString(),},
                    { id: 'FechaNac', label: 'Fecha Nacimiento', minWidth: 120, format: value => value.toLocaleString(),},
                    { id: 'TelCel', label: 'Tel Cel', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'FechaIng', label: 'Fecha Ingreso', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'FecUltP', label: 'Fecha Último Pedido', minWidth: 120, format: value => value.toFixed(2), },
                  ]}
              />
          );
    }
  }

  export default emprendedor