const listaPaises = [
{
    "id": 0,
    "nombre": "Seleccione.."
},
// {
//     "id": 1,
//     "nombre": "Ecuador"
// },
{
    "id": 2,
    "nombre": "Bolivia"
},
{
    "id": 3,
    "nombre": "Peru"
}
]

export default listaPaises;