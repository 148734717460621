import API from '../api/Ip';


const Controller = {
    ctrlgetIp: async() =>{
        return await API.getIp()     
        .then(resp => { 
            return resp.data.ip
        })
        .catch(err =>{        
            return err        
        })            
    },  
    
   
}

  
export default Controller


