import React, { PureComponent } from 'react';

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

class GraficBar extends PureComponent {
  
  render() {
    return (
      <BarChart
        width={350}
        height={170}
        data={this.props.data}
        margin={{
          top: 10, right: 20, left: 10, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
    
        <Bar dataKey = "uv" fill="#0080FF" />
        {/* <Bar dataKey = "uv" fill="rgba(75, 192, 192, 0.2)" border  /> */}
         {/* <Bar dataKey = {this.props.title} fill="#0080FF" /> */}
      </BarChart>
    );
  }
}

export default  GraficBar