

import API from '../api/Menu';


const Controller = {

    fetchDataInfoLider: async(lider,pais) =>{               
        return await API.getInfoLider(lider,pais)     
        .then(resp => {
            if (resp.data.success) {        
                return  resp.data.infoLider[0]
            }  
        })
        .catch(err =>{        
            return err
        
        })
            
    }
 
}



     
export default Controller


