import API from '../api/Productos';

const Controller = {
    fetchDataProductos: async(pais,idcdz) =>{         
         return await API.getProductos(pais,idcdz)     
        .then(resp => {
            if (resp.data.data.success === true) {       
                return resp
            }  
        })
        .catch(err =>{        
            return err        
        })            
    }, 
    fetchgetConsultaInventario: async(pais,codigo,qty,pedido,idcdz) =>{  
        return await API.getConsultaInventario(pais,codigo,qty,pedido,idcdz)     
       .then(resp => {   
             
                if (resp.data.success) {       
                    return  resp.data.data.infoInventario[0].Disponible
                    
                }  
            })
            .catch(err =>{        
                return err        
            })    
        }
}     
export default Controller
