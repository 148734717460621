import axios from 'axios'
import url from './url'


const Api = {

    getCompraPersonal: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/compras/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getGrupoDirecto: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/comprasgp/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getSaldoLider: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/saldo/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getVtasTotales: async (lider,pais) => {

        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/vtastotales/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getVtasComisionables: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/vtascomisionables/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getEmpActivas: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/empreactivas/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    },
    getNumLideres: async (lider,pais) => {      
        var data = {
            'idLider': lider,
            'pais': pais
        }
        return await axios.post(url+'/lider/numlideres/',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")
                    }
            }
        ) 
    }
}
export default Api