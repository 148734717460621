import React, { Component } from "react";
import Tabla from '../../Table/index';

class creditosLider extends Component {
    constructor(props){
        super(props);    

        this.state = {
          rows : this.props.rows, 
          active : false
        }
    }

      render(){
        const rows = this.props.rows;
        return (
              <Tabla
                rows = {rows}
                columns={ [
                    { id: 'idCliente', label: 'idCliente', minWidth: 100 },
                    { id: 'Nombre', label: 'Nombre', minWidth: 120 },
                    { id: 'Documento', label: 'Documento', minWidth: 100 },
                    { id: 'Saldo', label: 'Saldo', minWidth: 120, format: value => value.toFixed(2), },
                    { id: 'FechaDoc', label: 'Fecha Doc', minWidth: 120 },
                    { id: 'Comentarios', label: 'Estatus Venc.', minWidth: 120 },
                    { id: 'Cdz', label: 'Cdz', minWidth: 120 },

                  ]}
              />
          );
    }
  }

  export default creditosLider